import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';
import Controls from 'Components/layout/panel/PaginatedListElementControls';

export default class PanelStepAccordion extends Component {
    static propTypes = {
        currentStepKey: PropTypes.string,
        disabled: PropTypes.bool,
        visible: PropTypes.bool,
        steps: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                disabled: PropTypes.bool,
                title: PropTypes.shape({
                    headline: PropTypes.string.isRequired,
                    value: PropTypes.string,
                }),
                children: PropTypes.any,
                controls: PropTypes.arrayOf(
                    PropTypes.shape({
                        type: PropTypes.string,
                        visible: PropTypes.bool,
                        label: PropTypes.string,
                        style: PropTypes.string,
                        onClick: PropTypes.func,
                    })
                ),
            }),
        ),
    };
    static defaultProps = {
        currentStepKey: null,
        disabled: false,
        steps: [],
    };

    state = {
        currentStepKey: null,
    }

    componentDidMount = () => {
        const { currentStepKey, steps } = this.props;
        const firstStepKey = steps.length > 0 && steps[0].key || null;

        this.setState({
            currentStepKey: currentStepKey || firstStepKey,
        });
    }

    componentDidUpdate = prevProps => {
        const { currentStepKey } = this.props;

        if(prevProps.currentStepKey !== currentStepKey) {
            this.setState({ currentStepKey });
        }
    }

    onChangeStep = key => {
        this.setState({
            currentStepKey: key,
        });
    }

    onChangeStepKey = (nextStepIndex) => {
        const { steps } = this.props;
        const nextStep = steps[nextStepIndex];
        
        if(nextStep){
            this.onChangeStep(nextStep.key);
        }
    }

    render() {
        const { disabled, steps } = this.props;
        const { currentStepKey } = this.state;

        return (
            <StyledComponent
                className="panel-step-accordion"
                styles={require('./styles')}
            >
                <div className="steps">
                    {steps
                        .filter(step => step && step.visible !== false)
                        .map((step, stepIndex) => (
                            <div 
                                key={step.key}
                                className={classnames({
                                    step: true,
                                    disabled: Boolean(step.disabled),
                                    active: currentStepKey === step.key,
                                })}
                            >   
                                <div className="step-control">
                                    {currentStepKey === step.key && stepIndex > 0 && (
                                        <Button
                                            onClick={() => this.onChangeStepKey(stepIndex-1)}
                                            size="extraSmall"
                                            style="gradient"
                                        >
                                        Cofnij
                                        </Button>
                                    )}
                                </div>
                                {step.title && (
                                    <a 
                                        className="step-title"
                                        onClick={() => Boolean(!step.disabled) && this.onChangeStep(step.key)}
                                    >
                                        <div className="step-title-headline">
                                            <span className="step-title-headline-counter">
                                                {stepIndex+1}.
                                            </span>
                                            <span className="step-title-headline-content">
                                                {step.title.headline}
                                                {step.controls && (
                                                    <Controls 
                                                        className="control"
                                                        controls={step.controls}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                        {step.title.value && (
                                            <div className="step-title-value">
                                                {step.title.value}
                                            </div>
                                        )}
                                    </a>
                                )}
                                {currentStepKey === step.key && (
                                    <div className="step-body">
                                        {step.children || null}
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </StyledComponent>
        );
    }
}
