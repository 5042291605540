import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_USER_OFFER } from 'Consts/routes';
import { USER_OFFER_USERS_TYPE_MULTI } from 'Consts/userOffers';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getStatusLabel } from 'Utils/userOffer';
import { getTypeLabel } from 'Utils/offer';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class TrainerUserOffersList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        userOffers: PropTypes.object.isRequired,
        layout: PropTypes.string,
        type: PropTypes.string,
        isImageVisible: PropTypes.bool,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapClassName: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapImage: PropTypes.func,
        onMapType: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
    };
    static defaultProps = {
        layout: 'images',
        type: 'image',
        isImageVisible: true,
        onMapClassName:  (elem, value) => value,
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapImage: (elem, value) => value,
        onMapType: (elem, value) => value,
        onMapTitle: (elem, value) => value,
        onMapSubTitle: (elem, value) => value,
    };

    state = {};

    defaultQuery = {
        page: 1,
        perPage: 8,
        search: '',
        orderBy: '',
        userId: undefined,
        status: undefined,
        canLead: undefined,
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(predefinedQuery) !== JSON.stringify(prevProps.predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();
        
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { 
            userOffers, 
            location, 
            history, 
            layout, 
            type, 
            isImageVisible,
            onMapClassName,
            onMapControls,
            onMapAdditionals,
            onMapFilters,
            onMapLabels,
            onMapType,
            onMapImage,
            onMapTitle,
            onMapSubTitle,
        } = this.props;


        return (
            <StyledComponent
                className="trainer-user-offers-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userOffers}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    layout={layout}
                    onMapElement={element => (
                        console.log(onMapType(element, type)),
                        <PaginatedListElement
                            key={element.id}
                            className={onMapClassName(element, '')}
                            type={onMapType(element, type)}
                            // image={onMapImage(element, {
                            //     visible: isImageVisible,
                            //     url: require('Theme/images/offers/design/image.jpg'),
                            // })}
                            title={onMapTitle(element, element.name)}
                            subtitle={onMapSubTitle(element, `Status: ${getStatusLabel(element).label}`)}
                            additionals={onMapAdditionals(element, [{
                                name: 'Rodzaj zamówienia: ',
                                value: getTypeLabel(element.offer).label,
                            }, {
                                visible: Boolean(element.usersType === USER_OFFER_USERS_TYPE_MULTI),
                                name: 'Typ zamówienia: ',
                                value: 'Multi',
                            }, {
                                visible: Boolean(element.usersType === USER_OFFER_USERS_TYPE_MULTI),
                                name: 'Liczba adeptów: ',
                                value: element.usersLimit,
                            }])} 
                            labels={onMapLabels(element, [])}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: 'Szczegóły',
                                to: withVariables(TRAINER_USER_OFFER.path, { id: element.id }),
                                style: 'hollow',
                            }])}
                        />
                    )}
                    filters={onMapFilters([])}
                />
            </StyledComponent>
        );
    }
}
