import { connect } from 'react-redux';
import Component from './component';

import {
    cancel24h,
} from 'Redux/modules/trainer/events/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            cancel24h: dispatch(cancel24h),
        },
    }),
)(Component);