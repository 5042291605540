import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/trainer/userSurveys/actions';

export default connect(
    state => ({
        userSurveys: state.trainerUserSurveys.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    }),
)(Component);