import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { resetQueryString } from 'Utils/querystring';

import { USER_OFFER_STATUS_ACTIVE, USER_OFFER_STATUS_CLOSED } from 'Consts/userOffers';
import { RESOURCE_CLASS_WITH_PRODUCT_FREE_SLOTS } from 'Consts/resourceClasses';

import StyledComponent from 'Components/core/StyledComponent';
import UserOffersList from 'Components/trainer/userOffers/List';

export default class TrainerEventsPlannerStepUserOffer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        formState: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    componentDidMount = () => {
        const { location, history } = this.props;

        resetQueryString(location, history);
    }

    render() {
        const { location, history, formState, onChangeFormState } = this.props;
        
        return (
            <StyledComponent
                className="trainer-events-planner-step-user-offer"
                styles={require('./styles')}
            >
                <h3 className="list-headline">
                    Wybierz zamówienie  
                </h3>
                <UserOffersList 
                    location={location}
                    history={history}
                    predefinedQuery={{
                        userId: formState.user && formState.user.id,
                        statuses: [USER_OFFER_STATUS_ACTIVE, USER_OFFER_STATUS_CLOSED], 
                        canLead: true,
                        resourceClass: RESOURCE_CLASS_WITH_PRODUCT_FREE_SLOTS,
                    }}
                    onMapClassName={element => formState.userOffer && formState.userOffer.id === element.id
                        ? 'selected'
                        : ''
                    }
                    onMapControls={element => [{
                        type: 'button',
                        label:  formState.userOffer && formState.userOffer.id === element.id
                            ? 'Wybrano'
                            : 'Wybierz',
                        visible: true,
                        onClick: () => onChangeFormState({ userOffer: element }),
                        style: 'hollow',
                    }]}
                />
            </StyledComponent>
        );
    }
}