import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Logger from 'Services/Logger';

import {
    CANCEL_DIAGNOSTIC_REASONS,
} from 'Consts/events';

import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import MultiSelect from 'Components/forms/MultiSelect';
import Button from 'Components/layout/Button';

export default class TrainerCancelEventDiagnostic extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            cancelDiagnostic: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
        onSuccess: () => {},
    };

    state = {
        formState: {
            type: null,
        },
    };

    onSubmit = () => {
        const { actions, data, onSuccess } = this.props;
        const { formState } = this.state;

        return actions.cancelDiagnostic({
            id: data.id,
            ...formState,
            type: fromSelectObject(formState.type),
        })
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                toast.error('Musisz podać powód odwołania treningu');
                Logger.error('[TrainerCancelTrainingModal] Failed to cancel training', error);
                this.setState({
                    isPending: false,
                    errors: false,
                });

                throw error;
            });
    }

    render() {
        const { data } = this.props;
        const { formState } = this.state;
        
        if (!data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-events-cancel-diagnostic"
                styles={require('./styles')}
            >
                <MultiSelect
                    name="type"
                    label="Podaj powód odwołania treningu"
                    placeholder="Podaj powód odwołania treningu"
                    options={CANCEL_DIAGNOSTIC_REASONS.map(type => ({
                        value: type.key,
                        label: type.label,
                    }))}
                    value={formState.type}
                    onChange={({ value }) => this.setState(prevState => ({ 
                        formState: {
                            ...prevState.formState,
                            type: value,
                        },
                    }))}
                />
                <Button 
                    onClick={this.onSubmit} 
                    style="gradient"
                >
                    Odwolaj
                </Button>
            </StyledComponent>
        );
    }
}