import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { 
    API_RESOURCE_USER_OFFERS, 
    API_RESOURCE_PRODUCTS,
} from 'Consts/apiResources';
import { TRAINER_EVENTS, TRAINER_ADEPTS_MANAGE, TRAINER_EVENT_CYCLE, TRAINER_EVENT_MEET, ADEPT_EVENT_MEET } from 'Consts/routes';
import { EVENT_DONE_STATUS_NOT_DONE, EVENT_CATEGORY_PRIVATE, EVENT_CATEGORY_DEFAULT } from 'Consts/events';
import { USER_OFFER_STATUS_ACTIVE, USER_OFFER_STATUS_CLOSED } from 'Consts/userOffers';
import { LOCATION_TYPE_ONLINE } from 'Consts/locations';
import { OFFER_TYPE_DIAGNOSTIC } from 'Consts/offers';

import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';
import { getFormattedDate, toApiFormat as dateToApiFormat } from 'Utils/date';
import { getCategoryLabel } from 'Utils/event';
import { fromSelectObject } from 'Utils/object';
import { isSlotOccupied } from 'Utils/userWorkHours';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import StepAccordionV2 from 'Components/layout/panel/StepAccordionV2';
import StepAccordion from 'Components/layout/panel/StepAccordion';
import StepCategory from 'Components/trainer/events/Planner/steps/Category';
import StepUser from 'Components/trainer/events/Planner/steps/User';
import StepUserOffer from 'Components/trainer/events/Planner/steps/UserOffer';
import StepProduct from 'Components/trainer/events/Planner/steps/Product';
import StepLocation from 'Components/trainer/events/Planner/steps/Location';
import StepTime from 'Components/trainer/events/Planner/steps/Time';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';
import Form from 'Components/forms/Form';
import TextBox from 'Components/layout/panel/TextBox';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import CancelEvent from 'Components/trainer/modals/CancelEvent';

export default class TrainerEventsPlanner extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            cancel: PropTypes.func.isRequired,
            listUserOffers: PropTypes.func.isRequired,
            listProducts: PropTypes.func.isRequired,
            stats: PropTypes.func.isRequired,
            done: PropTypes.func.isRequired,
            reset: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
        onSuccess: PropTypes.func,
        onClose: PropTypes.func,
        forcePlanner: PropTypes.bool,
        disabledSteps: PropTypes.shape({
            category: PropTypes.bool,
            user: PropTypes.bool,
            userOffer: PropTypes.bool,
            product: PropTypes.bool,
            location: PropTypes.bool,
            time: PropTypes.bool,
        }),
        profile: PropTypes.object.isRequired,
    };
    
    static defaultProps = {
        data: null,
        predefinedState: {},
        forcePlanner: false,
        isDetailsExpanded: false,
        disabledSteps: {
            category: false,
            user: false,
            userOffer: false,
            product: false,
            location: false,
            time: false,
        },
    };

    state = {
        formState: {
            name: '',
        },
        products: [],
        isInitialized: false,
        forcePlanner: this.props.forcePlanner, //eslint-disable-line react/destructuring-assignment
        width: 0,
        height: 0,
        disabledSteps: {
            category: this.props.disabledSteps.category, //eslint-disable-line react/destructuring-assignment
            user: this.props.disabledSteps.user, //eslint-disable-line react/destructuring-assignment
            userOffer: this.props.disabledSteps.userOffer, //eslint-disable-line react/destructuring-assignment
            product: this.props.disabledSteps.product, //eslint-disable-line react/destructuring-assignment
            location: this.props.disabledSteps.location, //eslint-disable-line react/destructuring-assignment
            time: this.props.disabledSteps.time, //eslint-disable-line react/destructuring-assignment
        },
        cancelEvent: false,
        isOccupationModalVisible: false,
    };

    componentDidMount = () => {
        const { data, predefinedState } = this.props;

        this.setData();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
                ...predefinedState,
            },
        }), () => {
            const { formState } = this.state;
            
            if(formState && formState.user) {
                this.setData();
            }
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data, history, location } = this.props;
        const { formState, forcePlanner } = this.state;
        const queryObject = parseQueryToObject(location.search, true);

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                ...prevState,
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }), () => {
                const { formState } = this.state;
                if(formState && formState.user) {
                    this.setData();
                }
            });
        }

        if(forcePlanner) {
            // Clear page param on formState change
            if(queryObject.page && JSON.stringify(formState) !== JSON.stringify(prevState.formState)) {
                history.push(
                    withVariables(
                        location.pathname,
                        {},
                        { ...queryObject, page: undefined }
                    )
                );
            }

            // Clear next steps on user change
            if(formState.category && JSON.stringify(formState.category) !== JSON.stringify(prevState.formState.category)) {
                this.setState(prevState => ({
                    formState: {
                        ...prevState.formState,
                        user: null,
                        userOffer: null,
                        product: null,
                        location: null,
                        time: null,
                    },
                }));
            }

            // Clear next steps on user change
            if(formState.user && JSON.stringify(formState.user) !== JSON.stringify(prevState.formState.user)) {
                this.setState(prevState => ({
                    formState: {
                        ...prevState.formState,
                        userOffer: null,
                        product: null,
                        location: null,
                        time: null,
                    },
                }));
            }

            // Clear next steps on userOffer change
            if(formState.userOffer && JSON.stringify(formState.userOffer) !== JSON.stringify(prevState.formState.userOffer)) {
                this.setState(prevState => ({
                    formState: {
                        ...prevState.formState,
                        product: null,
                        location: null,
                        time: null,
                    },
                }));
            }

            // Clear next steps on product change
            if(formState.product && JSON.stringify(formState.product) !== JSON.stringify(prevState.formState.product)) {
                this.setState(prevState => ({
                    formState: {
                        ...prevState.formState,
                        location: null,
                        time: null,
                    },
                }));
            }

            // Clear next steps on location change
            if(formState.location && JSON.stringify(formState.location) !== JSON.stringify(prevState.formState.location)) {
                this.setState(prevState => ({
                    formState: {
                        ...prevState.formState,
                        time: null,
                    },
                }));
            }
        }
    }

    updateWindowDimensions = () => {
        this.setState({ 
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    setData = () => {
        const { actions, data } = this.props;
        const { formState } = this.state;

        // In case of update
        if(data && data.id) {
            this.setState({
                isInitialized: true,
            });

            return new Promise(resolve => resolve);
        }

        // In case of no user (vital on componentDidMount)
        if (!formState.user) {
            this.setState({
                isInitialized: true,
            });

            return new Promise(resolve => resolve);
        }

        //In case of create
        return new Promise(resolve => {
            actions.listUserOffers({ 
                page: 1, 
                perPage: 2, 
                userId: formState.user && formState.user.id,
                statuses: [USER_OFFER_STATUS_ACTIVE, USER_OFFER_STATUS_CLOSED], 
                canLead: true,
            })
                .then(response => {
                    const elements = response.payload[API_RESOURCE_USER_OFFERS].elements;
                 
                    if (!elements.length) {
                        if (formState.product || formState.userOffer) {
                            this.setState({
                                formState: {
                                    ...formState,
                                    product: null,
                                    userOffer: null,
                                },
                            });
                        }

                        return resolve();
                    }

                    if(elements.length > 1) {
                        return resolve();
                    }

                    const currentElement = elements[0];

                    this.setState(prevState => ({
                        ...prevState,
                        formState: {
                            ...prevState.formState,
                            userOffer: currentElement,
                        },
                    }), resolve);
                });
        })
            .then(() => {
                const { formState } = this.state;
                if(!formState.userOffer) {
                    return;
                }

                return actions.listProducts({ 
                    page: 1, 
                    perPage: 2, 
                    userOfferId: formState.userOffer && formState.userOffer.id, 
                })
                    .then(response => {
                        const elements = response.payload[API_RESOURCE_PRODUCTS].elements;
                        if(elements.length > 1) {
                            return;
                        }
        
                        const currentElement = elements[0];
                        this.setState(prevState => ({
                            ...prevState,
                            formState: {
                                ...prevState.formState,
                                product: currentElement,
                            },
                        }));
                    });
            })
            .then(() => {
                this.setState({
                    isInitialized: true,
                });
            });
    }

    dataToFormState = data => ({
        ...data,
        startAt: data.startAt && moment(data.startAt).toDate(),
        category: data.category || null,
    })

    formStateToApi = data => ({
        ...data,
        category: fromSelectObject(data.category),
        userOfferId: data.userOffer && data.userOffer.id,
        productId: data.product && data.product.id,
        leadId: data.lead && data.lead.id,
        locationId: data.location && data.location.id,
        startAt: dateToApiFormat(data.startAt, 'datetime', true),
        endAt: dateToApiFormat(data.endAt, 'datetime', true),
    })

    onSubmit = (redirect = true) => {
        const { data, profile } = this.props;
        const { formState } = this.state;
        const { userWorkHours } = profile;

        return new Promise((resolve, reject) => {
            if (isSlotOccupied(formState, userWorkHours)) {
                if (window.confirm('Czy na pewno chcesz zaplanować trening poza godzinami pracy?')) {  // resolve if slot is not in users work hours
                    resolve();
                } else {
                    reject();
                }
            } else {
                resolve();
            }
        }).then(() => {
            return data && data.id
                ? this.onUpdate(formState, redirect)
                : this.onCreate(formState);
        });
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    onCreate = formState => {
        const { actions, history, onClose } = this.props;
        
        return new Promise((resolve, reject) => {
            if (formState.category === EVENT_CATEGORY_PRIVATE) {
                resolve();
            }

            return actions.stats({ userOfferId: formState?.userOffer?.id })
                .then(response => {
                    resolve();
                });
        }).then(() => {
            return actions.create({
                ...this.formStateToApi(formState),
            })
                .then(response => {
                    history.push(
                        withVariables(
                            TRAINER_EVENTS.path,
                            {},
                            {
                                tab: 'eventsCalendar',
                                date: moment(formState.startAt).toDate(),
                                timestamp: moment().toDate(),
                            },
                        )
                    );
                    if (onClose) {
                        onClose();
                    }
                })
                .catch((error) => {
                    Object.keys(error.payload.validationErrors).map(fieldName => {                       
                        error.payload.validationErrors[fieldName].map(errorMessage => {
                            toast.error(errorMessage);
                        });
                    });

                    if (Object.keys(error.payload.validationErrors).length == 0) {
                        toast('Wystąpił błąd, spróbuj ponownie później');
                    }
                });
        }); 
    }

    onUpdate = (formState, redirect) => {
        const { data, actions, onClose, history } = this.props;
    
        return actions.update({
            id: data.id,
            ...this.formStateToApi(formState),
        })
            .then(response => {
                if (!redirect) return;
                history.push(
                    withVariables(
                        TRAINER_EVENTS.path,
                        {},
                        {
                            tab: 'eventsCalendar',
                            date: moment(formState.startAt).toDate(),
                            timestamp: moment().toDate(),
                        },
                    )
                );
                if (onClose) {
                    onClose();
                }
            })
            .catch((error) => {
                Object.keys(error.payload.validationErrors).map(fieldName => {                       
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(errorMessage);
                    });
                });

                if (Object.keys(error.payload.validationErrors).length == 0) {
                    toast('Wystąpił błąd, spróbuj ponownie później');
                }
            });
    }

    getCurrentStepKey = () => {
        const { formState } = this.state;

        if (!formState.category) return 'category';
        if (!this.isEventPrivate(formState)) {
            if (!formState.user) return 'user';
            if (!formState.userOffer) return 'userOffer';
            if (!formState.product) return 'product';
            if (!formState.location) return 'location';
        }

        return 'time';
    }

    isEventPrivate = formState => {
        return fromSelectObject(formState.category) === EVENT_CATEGORY_PRIVATE;
    }

    isEventDefault = formState => {
        return fromSelectObject(formState.category) === EVENT_CATEGORY_DEFAULT;
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { location, history, actions, data, predefinedState, disabledSteps, onClose } = this.props;
        const { formState, isInitialized, forcePlanner, width, cancelEvent, isDetailsExpanded, isOccupationModalVisible } = this.state;
        const currentStepKey = this.getCurrentStepKey();

        if (!isInitialized || (data && !Object.keys(formState).length) || !currentStepKey) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-panel-events-planner"
                styles={require('./styles')}
            >
                {data && !forcePlanner
                    ? (
                        <div className="dont-force-planner">
                            <div className="dont-force-planner">
                                <div className="details">
                                    {formState.category === EVENT_CATEGORY_PRIVATE && (
                                        <div className="detail-item name">
                                            <TextBox 
                                                title="Nazwa wydarzenia"
                                                subtitle={formState.name || 'Brak'}
                                                image={{
                                                    isVisible: true,
                                                    url: require('Theme/images/icons/star.svg'),
                                                }}
                                                styleVersion={2}
                                            />
                                        </div>
                                    )}

                                    {formState.category !== EVENT_CATEGORY_PRIVATE && (
                                        <div className="detail-item user">
                                            <TextBox 
                                                title="Klient"
                                                subtitle={formState.user && (
                                                    <Link
                                                        className="user-link"
                                                        to={withVariables(TRAINER_ADEPTS_MANAGE.path, { id: formState.user.id }, {} )}
                                                    >
                                                        {getFullName(formState.user).label}
                                                    </Link>
                                                )}
                                                image={{
                                                    isVisible: true,
                                                    url: require('Theme/images/icons/user.svg'),
                                                }}
                                                styleVersion={2}
                                            />
                                        </div>
                                    )}
                                    <div className="detail-item time">
                                        <TextBox 
                                            title="Data"
                                            subtitle={formState.startAt && formState.endAt 
                                                ? `${getFormattedDate(formState.startAt, 'datetime')} - ${getFormattedDate(formState.endAt, 'datetime')}`
                                                : 'Brak'
                                            }
                                            image={{
                                                isVisible: true,
                                                url: require('Theme/images/icons/calendar.svg'),
                                            }}
                                            styleVersion={2}
                                        />
                                    </div>
                                        
                                    {!isDetailsExpanded && (
                                        <div className="expand-details">
                                            <a 
                                                className="expand-details-link"
                                                onClick={() => this.setState({ isDetailsExpanded: true })}
                                            >
                                                Rozwiń szczegóły
                                            </a>
                                        </div>
                                    )}

                                    {isDetailsExpanded && (
                                        <>
                                            <div className="detail-item category">
                                                <TextBox 
                                                    title="Kategoria"
                                                    subtitle={getCategoryLabel(formState).label}
                                                    image={{
                                                        isVisible: true,
                                                        url: require('Theme/images/icons/training.svg'),
                                                    }}
                                                    styleVersion={2}
                                                />
                                            </div>

                                            {formState.category !== EVENT_CATEGORY_PRIVATE && (
                                                <> 
                                                    <div className="detail-item phone">
                                                        <TextBox 
                                                            title="Numer telefonu"
                                                            subtitle={formState.user && formState.user.phone || 'Brak'}
                                                            image={{
                                                                isVisible: true,
                                                                url: require('Theme/images/icons/mobile-solid.svg'),
                                                            }}
                                                            styleVersion={2}
                                                        />
                                                    </div>
                                                    <div className="detail-item userOffer">
                                                        <TextBox 
                                                            title="Zamówienie"
                                                            subtitle={formState.userOffer && formState.userOffer.name || 'Brak'}
                                                            image={{
                                                                isVisible: true,
                                                                url: require('Theme/images/icons/wallet.svg'),
                                                            }}
                                                            styleVersion={2}
                                                        />
                                                    </div>
                                                    <div className="detail-item product"> 
                                                        <TextBox 
                                                            title="Produkt"
                                                            subtitle={formState.product && formState.product.name || 'Brak'}
                                                            image={{
                                                                isVisible: true,
                                                                url: require('Theme/images/icons/finance.svg'),
                                                            }}
                                                            styleVersion={2}
                                                        />
                                                    </div>
                                                    <div className="detail-item location">
                                                        <TextBox 
                                                            title="Lokalizacja"
                                                            subtitle={formState.location && formState.location.name || 'Brak'}
                                                            image={{
                                                                isVisible: true,
                                                                url: require('Theme/images/icons/location.svg'),
                                                            }}
                                                            styleVersion={2}
                                                        />
                                                    </div>
                                                    <div className="detail-item user-offer-users-type">
                                                        <TextBox 
                                                            title="Ilość użytkowników biorących udział"
                                                            subtitle={data.usersCount}
                                                            image={{
                                                                isVisible: true,
                                                                url: require('Theme/images/icons/user-friends.svg'),
                                                            }}
                                                            styleVersion={2}
                                                        />
                                                    </div>
                                                    {data.eventCycle && (
                                                        <div className="detail-item is-from-event-cycle">
                                                            <TextBox
                                                                title="Jest wydarzeniem z cyklu"
                                                                subtitle={(
                                                                    <Link
                                                                        className="event-cycle-link link"
                                                                        to={withVariables(
                                                                            TRAINER_EVENT_CYCLE.path,
                                                                            { id: data.eventCycle.originEventId },
                                                                            {}
                                                                        )}
                                                                    >
                                                                        Przejdź do cyklu
                                                                    </Link>
                                                                )}
                                                                image={{
                                                                    isVisible: true,
                                                                    url: require('Theme/images/icons/cycle.svg'),
                                                                }}
                                                                styleVersion={2}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {(formState.lead && formState.lead.googleMeetUrl) && (formState.location && formState.location.type == LOCATION_TYPE_ONLINE) && (
                                                <div className="detail-item online-training">
                                                    <TextBox 
                                                        title="Link do treningu online"
                                                        subtitle={<a className="link" href={formState.lead.googleMeetUrl} target="_blank" rel="noopener noreferrer"> {formState.lead.googleMeetUrl} </a>} 
                                                        image={{
                                                            isVisible: true,
                                                            url: require('Theme/images/icons/star.svg'),
                                                        }}
                                                        styleVersion={2}
                                                    />
                                                </div>
                                            )}
                                            {(formState.location && formState.location.type == LOCATION_TYPE_ONLINE) && (
                                                <div className="detail-item online-training-adept-url">
                                                    <TextBox 
                                                        title="Link do spotkania dla adepta"
                                                        subtitle={process.env.APP_URL  + withVariables(ADEPT_EVENT_MEET.path, { id: formState.id })} 
                                                        image={{
                                                            isVisible: true,
                                                            url: require('Theme/images/icons/star.svg'),
                                                        }}
                                                        styleVersion={2}
                                                    />
                                                </div>
                                            )}
                                            {(formState.userOffer && formState.userOffer.notes) && (
                                                <div className="detail-item notes">
                                                    <TextBox 
                                                        title="Notatka do zamówienia"
                                                        subtitle={formState.userOffer.notes} 
                                                        styleVersion={2}
                                                    />
                                                </div>
                                            )}
                                            <ElementEditor
                                                location={location}
                                                history={history}
                                                className="comments-editor"
                                                layout="box"
                                                styleVersion={2} 
                                                header={(
                                                    <div className="comments-editor-header">
                                                        <div className="comments-editor-header-col col-left">
                                                            <h2 className="comments-editor-header-headline">
                                                                {formState.category && (
                                                                    formState.category === EVENT_CATEGORY_PRIVATE
                                                                        ? 'Komentarz'
                                                                        : 'Komentarz i plan treningowy'
                                                                )}
                                                        
                                                            </h2>
                                                        </div>
                                                        <div className="training-targets-editor-header-col col-right">
                                                            <Button
                                                                className="measurements-editor-header-button"
                                                                onClick={() => this.onSubmit}
                                                                type='submit'
                                                                form='editor'
                                                                style='gradient'
                                                            >
                                                                Zapisz
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                                forms={[{
                                                    name: 'editor',
                                                    submitAction: () => this.onSubmit(false),
                                                    submitVisible: false,
                                                    data: formState,
                                                    layout: 'box',
                                                    onStateChange: formState => {
                                                        this.setState({
                                                            formState,
                                                        });
                                                    },
                                                    elements: [{
                                                        type: 'textarea',
                                                        name: 'comment',
                                                        label: 'Komentarz (niewidoczny dla adepta)',
                                                        size: 'fullWidth',
                                                        inputProps: {
                                                            styleVersion: 2,
                                                            border: 'light-2', 
                                                        },
                                                    }, {
                                                        type: 'textarea',
                                                        name: 'trainingPlan',
                                                        label: 'Plan treningowy (widoczny dla adepta)',
                                                        size: 'fullWidth',
                                                        isVisible: Boolean(formState.category !== EVENT_CATEGORY_PRIVATE),
                                                        inputProps: {
                                                            styleVersion: 2,
                                                            border: 'light-2', 
                                                        },
                                                    }],
                                                }]}
                                            />  

                                            {formState.category == EVENT_CATEGORY_PRIVATE && (
                                                <Form
                                                    data={formState}
                                                    onSubmit={this.onSubmit}
                                                    onStateChange={this.onStateChange}
                                                >
                                                    <Input 
                                                        name="name"
                                                        label="Nazwa wydarzenia"
                                                        styleVersion={2}
                                                        border='light'
                                                    />
                                                    <Button
                                                        className="submit-button"
                                                        type="submit"
                                                        layout="fullWidth"
                                                        size="large"
                                                        style="gradient"
                                                    >
                                                        Zapisz
                                                    </Button>
                                                </Form>
                                            )}
                                        </>
                                    )}
                                </div>
                                
                            </div>
                            <div className="route-select">
                                {(data.canMove || data.canDone || data.canCancelWithoutDateRange) && (
                                    <h2 className="route-select-headline">
                                        Co chcesz zrobić?
                                    </h2>
                                )}
                                {(formState.lead && formState.lead.googleMeetUrl) && (formState.location && formState.location.type == LOCATION_TYPE_ONLINE) && !formState.lead.onlineMeetUrl && (
                                    <div className="routes">
                                        <a className="route" href={formState.lead.googleMeetUrl} target="_blank" rel="noopener noreferrer">
                                            <h3 className="route-headline">
                                                Rozpocznij wideotrening
                                            </h3>
                                        </a>
                                    </div>
                                )}
                                {formState.location && formState.location.type === LOCATION_TYPE_ONLINE && formState.lead && formState.lead.onlineMeetHostUrl && (
                                    <div className="routes">
                                        <a
                                            className="route"
                                            onClick={() => {
                                                history.push(
                                                    withVariables(TRAINER_EVENT_MEET.path, { id: data.id })
                                                );
                                            }}
                                        >
                                            <h3 className="route-headline">
                                                Przejdź do spotkania
                                            </h3>
                                            <p className="route-hint">
                                                Możesz przejść do spotkania online.
                                            </p>
                                        </a>
                                    </div>
                                )}
                                <div className="routes">
                                    {data.canMove && ( 
                                        <a 
                                            className="route"
                                            onClick={() => this.setState({ forcePlanner: true })}
                                        >
                                            <h3 className="route-headline">
                                                Przełóż wydarzenie
                                            </h3>
                                            <p className="route-hint">
                                                Możesz przełożyć wydarzenie.
                                            </p>
                                        </a>
                                    )}
                                    {data.canCancel && formState.offer?.type !== OFFER_TYPE_DIAGNOSTIC && (
                                        <a 
                                            className="route"
                                            onClick={() => this.setState({ cancelEvent: true })}
                                        >
                                            <h3 className="route-headline">
                                                Odwołaj trening (24h)
                                            </h3>
                                            <p className="route-hint">
                                                Możesz odwołać trening.
                                                <br/>
                                                {data?.offer?.type !== OFFER_TYPE_DIAGNOSTIC && (
                                                    'Trening zostanie uznany jako wykonany.'
                                                )}
                                            </p>
                                        </a>
                                    )}
                                    {formState.offer?.type !== OFFER_TYPE_DIAGNOSTIC && (
                                        <a 
                                            className="route"
                                            onClick={() => {
                                                actions.reset({ id: data?.id })
                                                    .then(() => {
                                                        toast('Wydarzenie zostało usunięte z kalendarza');

                                                        if (onClose) return onClose();

                                                        history.push(TRAINER_EVENTS.path);
                                                    });
                                            }}
                                        >
                                            <h3 className="route-headline">
                                                Usuń z kalendarza
                                            </h3>
                                            <p className="route-hint">
                                                Możesz usunąć wydarzenie z kalendarza. 
                                                <br/>
                                                Trening wróci do puli adepta.
                                            </p>
                                        </a>
                                    )}
                                    {formState.offer?.type === OFFER_TYPE_DIAGNOSTIC && (
                                        <a 
                                            className="route"
                                            onClick={() => this.setState({ cancelEvent: true })}
                                        >
                                            <h3 className="route-headline">
                                                Odwołaj trening diagnostyczny
                                            </h3>
                                            <p className="route-hint">
                                                Możesz odwołać trening diagnostyczny.
                                            </p>
                                        </a>
                                    )}
                                    {data.canDone && (
                                        <a 
                                            className="route"
                                            onClick={() => {
                                                actions.done({ id: data && data.id })
                                                    .then(() => {
                                                        toast('Wysłano prośbę o potwierdzenie wykonania');

                                                        if (onClose) return onClose();

                                                        history.push(TRAINER_EVENTS.path);
                                                    });
                                            }}
                                        >
                                            <h3 className="route-headline">
                                                {data.offer.type === OFFER_TYPE_DIAGNOSTIC 
                                                    ? 'Oznacz jako wykonany'
                                                    : 'Wyślij prośbę o potwierdzenie'
                                                }
                                            </h3>
                                            <p className="route-hint">
                                                {data.offer.type === OFFER_TYPE_DIAGNOSTIC
                                                    ? 'Możesz oznaczyć wydarzenie jako wykonane'
                                                    : 'Możesz wysłać prośbę o potwierdzenie wykonania.'
                                                }
                                            </p>
                                        </a>
                                    )}
                                    {!data.eventCycle && (
                                        <a
                                            className="route"
                                            onClick={() => {
                                                history.push(
                                                    withVariables(
                                                        TRAINER_EVENT_CYCLE.path,
                                                        { id: data.id },
                                                        {}
                                                    )
                                                );
                                            }}
                                        >
                                            <h3 className="route-headline">
                                                Zarządzaj cyklem
                                            </h3>
                                            <p className="route-hint">
                                                Przejdź do zarządzania cyklem.
                                            </p>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {width > 768 && (
                                <StepAccordionV2
                                    currentStepKey={this.getCurrentStepKey()}
                                    disabled={Boolean(data && data.doneStatus !== EVENT_DONE_STATUS_NOT_DONE)}
                                    onSubmit={this.onSubmit}
                                    canSubmit={Boolean(formState.endAt && moment(formState.endAt).isAfter(moment(formState.startAt)))}
                                    formState={formState}
                                    steps={[{
                                        key: 'category',
                                        visible: true,
                                        disabled: Boolean(data && data.id || disabledSteps.category),
                                        title: {
                                            headline: 'Kategoria',
                                            value: getCategoryLabel(formState).label, 
                                        },
                                        children: (
                                            <StepCategory
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                            />
                                        ),
                                    }, {
                                        key: 'user',
                                        visible: Boolean(this.isEventDefault(formState)),
                                        disabled: Boolean((data && data.id) || !formState.category || disabledSteps.user),
                                        title: {
                                            headline: 'Klient',
                                            value: formState.user && getFullName(formState.user).label,
                                        },
                                        children: (
                                            <StepUser
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => {
                                                    this.setState(prevState => ({ 
                                                        ...prevState, 
                                                        formState: {
                                                            ...prevState.formState,
                                                            ...nexFormState,
                                                        },
                                                    }), this.setData);
                                                }}
                                            />
                                        ),
                                    }, {
                                        key: 'userOffer',
                                        visible: Boolean(this.isEventDefault(formState)),
                                        disabled: Boolean((data && data.id) || !formState.user || disabledSteps.userOffer),
                                        title: {
                                            headline: 'Zamówienie',
                                            value: formState.userOffer && formState.userOffer.name,
                                        },
                                        children: (
                                            <StepUserOffer
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                            />
                                        ),
                                    }, {
                                        key: 'product',
                                        visible: Boolean(this.isEventDefault(formState)),
                                        disabled: Boolean((data && data.id) || !formState.userOffer || disabledSteps.product),
                                        title: {
                                            headline: 'Produkt',
                                            value: formState.product && formState.product.name,
                                        },
                                        children: (
                                            <StepProduct
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                            />
                                        ),
                                    }, {
                                        key: 'location',
                                        visible: Boolean(!predefinedState.location && !this.isEventPrivate(formState)),
                                        disabled: Boolean((data && data.id) || !formState.product || disabledSteps.location),
                                        title: {
                                            headline: 'Lokalizacja',
                                            value: formState.location && formState.location.name,
                                        },
                                        children: (
                                            <StepLocation
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                            />
                                        ),
                                    }, {
                                        key: 'time',
                                        visible: true,
                                        disabled: this.isEventPrivate(formState)
                                            ? Boolean(!formState.category || disabledSteps.time) 
                                            : Boolean(!formState.location || disabledSteps.time),
                                        title: {
                                            headline: 'Termin',
                                            value: formState.startAt && formState.endAt 
                                                ? `${getFormattedDate(formState.startAt, 'datetime')} - ${getFormattedDate(formState.endAt, 'datetime')}` 
                                                : null,
                                        },
                                        children: (
                                            <StepTime
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                                mobileView={false}
                                            />
                                        ),
                                    }]}
                                />
                            )}
                            {width <= 768 && (
                                <>
                                    <StepAccordion
                                        currentStepKey={this.getCurrentStepKey()}
                                        disabled={Boolean(data && data.doneStatus !== EVENT_DONE_STATUS_NOT_DONE)}
                                        onSubmit={this.onSubmit}
                                        canSubmit={Boolean(formState.endAt && (formState.endAt && moment(formState.endAt).isAfter(moment(formState.startAt))))}
                                        formState={formState}
                                        steps={[{
                                            key: 'category',
                                            visible: true,
                                            disabled: Boolean((data && data.id) || !formState.category || disabledSteps.category),
                                            title: {
                                                headline: 'Kategoria',
                                                value: getCategoryLabel(formState).label, 
                                            },
                                            children: (
                                                <StepCategory
                                                    location={location}
                                                    history={history}
                                                    formState={formState}
                                                    onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                        ...prevState, 
                                                        formState: {
                                                            ...prevState.formState,
                                                            ...nexFormState,
                                                        },
                                                    }))}
                                                />
                                            ),
                                        }, {
                                            key: 'user',
                                            visible: Boolean(this.isEventDefault(formState)),
                                            disabled: Boolean((data && data.id) || !formState.category || disabledSteps.user),
                                            title: {
                                                headline: 'Klient',
                                                value: formState.user && getFullName(formState.user).label,
                                            },
                                            children: (
                                                <StepUser
                                                    location={location}
                                                    history={history}
                                                    formState={formState}
                                                    onChangeFormState={nexFormState => {
                                                        this.setState(prevState => ({ 
                                                            ...prevState, 
                                                            formState: {
                                                                ...prevState.formState,
                                                                ...nexFormState,
                                                            },
                                                        }), this.setData);
                                                    }}
                                                />
                                            ),
                                        }, {
                                            key: 'userOffer',
                                            visible: Boolean(this.isEventDefault(formState)),
                                            disabled: Boolean((data && data.id) || !formState.user || disabledSteps.userOffer),
                                            title: {
                                                headline: 'Zamówienie',
                                                value: formState.userOffer && formState.userOffer.name,
                                            },
                                            children: (
                                                <StepUserOffer
                                                    location={location}
                                                    history={history}
                                                    formState={formState}
                                                    onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                        ...prevState, 
                                                        formState: {
                                                            ...prevState.formState,
                                                            ...nexFormState,
                                                        },
                                                    }))}
                                                />
                                            ),
                                        }, {
                                            key: 'product',
                                            visible: Boolean(this.isEventDefault(formState)),
                                            disabled: Boolean((data && data.id) || !formState.userOffer || disabledSteps.product),
                                            title: {
                                                headline: 'Produkt',
                                                value: formState.product && formState.product.name,
                                            },
                                            children: (
                                                <StepProduct
                                                    location={location}
                                                    history={history}
                                                    formState={formState}
                                                    onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                        ...prevState, 
                                                        formState: {
                                                            ...prevState.formState,
                                                            ...nexFormState,
                                                        },
                                                    }))}
                                                />
                                            ),
                                        }, {
                                            key: 'location',
                                            visible: Boolean(!predefinedState.location && !this.isEventPrivate(formState)),
                                            disabled: Boolean((data && data.id) || !formState.product || disabledSteps.location),
                                            title: {
                                                headline: 'Lokalizacja',
                                                value: formState.location && formState.location.name,
                                            },
                                            children: (
                                                <StepLocation
                                                    location={location}
                                                    history={history}
                                                    formState={formState}
                                                    onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                        ...prevState, 
                                                        formState: {
                                                            ...prevState.formState,
                                                            ...nexFormState,
                                                        },
                                                    }))}
                                                />
                                            ),
                                        }, {
                                            key: 'time',
                                            visible: true,
                                            disabled: this.isEventPrivate(formState)
                                                ? Boolean(!formState.category || disabledSteps.time) 
                                                : Boolean(!formState.location || disabledSteps.time),
                                            title: {
                                                headline: 'Termin',
                                                value: formState.startAt && formState.endAt 
                                                    ? `${getFormattedDate(formState.startAt, 'datetime')} - ${getFormattedDate(formState.endAt, 'datetime')}` 
                                                    : null,
                                            },
                                            children: (
                                                <StepTime
                                                    location={location}
                                                    history={history}
                                                    formState={formState}
                                                    onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                        ...prevState, 
                                                        formState: {
                                                            ...prevState.formState,
                                                            ...nexFormState,
                                                        },
                                                    }))}
                                                    mobileView={true}
                                                />
                                            ),
                                        }]}
                                    />
                                    <div className="footer">
                                        <Button 
                                            onClick={this.onSubmit}
                                            // disabled={Boolean(!formState.endAt)}
                                            disabled={Boolean(formState.endAt && moment(formState.endAt).isBefore(moment(formState.startAt)))}
                                            size="large"
                                            layout="fullWidth"
                                            style="gradient"
                                        >
                                            Zaplanuj
                                        </Button>
                                    </div>
                                </>
                            )}
                    </>
                    )}
                <CancelEvent
                    location={location}
                    history={history}
                    isOpen={Boolean(cancelEvent)}
                    title={formState.offer?.type === OFFER_TYPE_DIAGNOSTIC
                        ? 'Odwołaj trening diagnostyczny'
                        : 'Odwołaj trening (24h)'
                    }
                    onClose={() => {
                        this.setState({ cancelEvent: false });
                    }}
                    data={data}
                    onSuccess={() => {
                        this.setState({ cancelEvent: false });
                        onClose && onClose();
                    }}
                />
            </StyledComponent>
        );
    }
}