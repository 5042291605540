import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 80vw;
    min-height: auto;
    max-height: 98vh;

    .ReactModal__Content {
        height: 100%;
    }

    .modal-body-component {
        max-height: initial !important;
    }

    .trainer-panel-events-planner {
        width: 100%;
    }
`;
