import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { TRAINER_EVENTS_MANAGE, TRAINER_EVENTS_CREATE } from 'Consts/routes';
import { 
    EVENT_CATEGORY_DEFAULT, 
    EVENT_DONE_STATUS_DONE, 
    EVENT_DONE_STATUS_NOT_DONE,
    EVENT_PAID_STATUS_PAID, 
    EVENT_PAID_STATUS_NOT_PAID, 
    EVENT_PAID_STATUS_CREDIT,
} from 'Consts/events';
import { OFFER_TYPE_DIAGNOSTIC } from 'Consts/offers';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getPaidStatusLabel, getDoneStatusLabel, getCategoryLabel } from 'Utils/event';
import { getFullName as getUserFullName } from 'Utils/user';
import { getFormattedDate, translateDateToDay } from 'Utils/date';
import { translateTimeUnit } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';
import Button from 'Components/layout/Button';

export default class TrainerEventsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            done: PropTypes.func.isRequired,
        }).isRequired,
        events: PropTypes.object.isRequired,
        header: PropTypes.object,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapClassName: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapImage: PropTypes.func,
        onMapType: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
        allAdeptEvents: PropTypes.bool,
    };
    static defaultProps = {
        header: {
            visible: true,
        },
        predefinedQuery: {},
        onMapClassName:  (elem, value) => value,
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapImage: (elem, value) => value,
        onMapType: (elem, value) => value,
        onMapTitle: (elem, value) => value,
        onMapSubTitle: (elem, value) => value,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        allAdeptEvents: false,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (
            JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject) ||
            JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)  //eslint-disable-line react/destructuring-assignment
        ) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, allAdeptEvents } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject, allAdeptEvents });
        }, 500);
    }

    render() {
        const { 
            events, 
            location, 
            history,
            header,
            onMapClassName,
            onMapControls,
            onMapAdditionals,
            onMapFilters,
            onMapLabels,
            onMapType,
            onMapImage,
            onMapTitle,
            onMapSubTitle,
            predefinedQuery,
            actions,
        } = this.props;

        const elementGroupDates = {};
        const elementGroups = {};
        events.elements.forEach(element => {
            if(!elementGroupDates[getFormattedDate(element.startAt, 'date')]) {
                elementGroupDates[getFormattedDate(element.startAt, 'date')] = element.id;
                elementGroups[element.id] = getFormattedDate(element.startAt, 'date');
            }
        });

        return (
            <StyledComponent
                className="trainer-events-list"
                styles={require('./styles')}
            >
                {header && header.visible && (
                    <div className="list-header">
                        <div className="controls">
                            <NavLink to={withVariables(TRAINER_EVENTS_CREATE.path, {}, { userId: predefinedQuery && predefinedQuery.userId })}>
                                <Button
                                    style="gradient"
                                    size="large"
                                >
                                    Zaplanuj wydarzenie
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                )}
                <PaginatedList
                    location={location}
                    history={history}
                    collection={events}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    onMapElement={element => {
                        const durationDisplayLabel = element?.product?.displayDurationValue
                            ? `, Czas trwania dla klienta: ${element.product.displayDurationValue} ` + translateTimeUnit(element.product)
                            : '';

                        return (
                            <div className="trainer-events-list-wrapper" key={element.id}>
                                {elementGroups[element.id] && (
                                    <div className="day-group">
                                    Wydarzenia z dnia: {getFormattedDate(element.startAt, 'DD.MM.YYYY')} ({translateDateToDay(element.startAt)})
                                    </div>
                                )}
                                <PaginatedListElement
                                    key={element.id}
                                    className={onMapClassName(element, '')}
                                    type={onMapType(element, undefined)}
                                    image={onMapImage(element, { 
                                        visible: true, 
                                        url: element.user && element.user.avatar,
                                        shape: 'circle',
                                    })}
                                    labels={[{
                                        isVisible: element.paidStatus === EVENT_PAID_STATUS_PAID,
                                        label: `Trening: ${element.order || '-'}/${element.totalCount}`,
                                    }]}
                                    title={onMapSubTitle(element, `${element.startAt ? `${translateDateToDay(element.startAt)} ${getFormattedDate(element.startAt, 'time')}` : 'Brak daty rozpoczęcia'} - ${element.endAt ? getFormattedDate(element.endAt, 'time') : 'Brak daty zakończenia'}`)}
                                    description={'Data rozpoczęcia: ' + `${element.startAt ? getFormattedDate(element.startAt) : 'Brak daty rozpoczęcia'}${durationDisplayLabel}`}
                                    subtitle={onMapTitle(element, element.user && getUserFullName(element.user, 'nameSurname').label || 'Brak')}
                                    additionals={onMapAdditionals(element, [{
                                        visible: element.category === EVENT_CATEGORY_DEFAULT,
                                        name: 'Produkt',
                                        value: element.product && element.product.name || 'Brak',
                                    }, {
                                        name: 'Status treningu',
                                        value: [{
                                            isVisible: element.paidStatus === EVENT_PAID_STATUS_NOT_PAID || element.paidStatus === EVENT_PAID_STATUS_CREDIT,
                                            label: getPaidStatusLabel(element).label,
                                            state: getPaidStatusLabel(element).stateColor,
                                        }, {
                                            isVisible: element.doneStatus === EVENT_DONE_STATUS_DONE,
                                            label: getDoneStatusLabel(element).label,
                                            state: getDoneStatusLabel(element).stateColor,
                                        }, {
                                            isVisible: element.doneStatus === EVENT_DONE_STATUS_NOT_DONE && moment(element.startAt).isBefore(moment()),
                                            label: getDoneStatusLabel(element).label,
                                            state: getDoneStatusLabel(element).stateColor,
                                        }, {
                                            isVisible: element.doneStatus === EVENT_DONE_STATUS_NOT_DONE && moment(element.startAt).isAfter(moment()),
                                            label: 'Zaplanowany',
                                            state: 'planned',
                                        }],
                                        type: 'labels',
                                    }])}
                                    controls={onMapControls(element, [{
                                        type: 'button',
                                        label: 'Oznacz jako wykonane',
                                        visible: element.canDone && Boolean(element?.offer?.type === OFFER_TYPE_DIAGNOSTIC),
                                        onClick: () => {
                                            actions.done({ id: element.id })
                                        },
                                        style: 'gradient',
                                    }, {
                                        type: 'button',
                                        label: 'Zarządzaj',
                                        visible: true,
                                        to: withVariables(TRAINER_EVENTS_MANAGE.path, { id: element.id }),
                                        style: 'gradient',
                                    }])}
                                />
                            </div>
                        );
                    }}
                    filters={onMapFilters([])}
                />
            </StyledComponent>
        );
    }
}
