import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .dont-force-planner {
        .details {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 1em;

            .expand-details {
                width: 100%;
                text-align: center;
                margin: 1em auto;

                .expand-details-link {
                    color: ${variables.dpc_fontDark};
                    text-decoration: underline;
                    font-size: 1.1em;
                    cursor: pointer;
                }
            }

            .detail-item {
                display: flex;
                margin-bottom: .75em;
                color: white;
                width: 49.5%;

                .admin-panel-text-box {
                    width: 100%;

                    .admin-panel-box {
                        .box-wrapper {
                            .right-block {
                                .subtitle {
                                    a {
                                        .link {
                                            color: ${variables.dpc_fontDark};
                                            text-decoration: underline;

                                            &:hover {
                                                color: rgba(0,0,0,.8);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    

                    .user-link {
                        color: ${variables.dpc_fontDark};
                    }
                }

                .label {
                    margin-right: .2em;
                }
                .value {
                    font-weight: 300;
                }

                &.notes {
                    .admin-panel-text-box {
                        .admin-panel-box {
                            .box-wrapper {
                                .right-block {
                                    width: 100%;

                                    .subtitle {
                                        width: 100%;
                                        font-weight: 400;
                                        font-size: 1.2em;
                                        white-space: break-spaces;
                                        max-height: 3em;
                                        overflow-y: scroll;
                                    
                                        &::-webkit-scrollbar {
                                            width: .5em;
                                        }

                                        &::-webkit-scrollbar-track {
                                            background-color: #393939; 
                                            border-radius: 3em;
                                        }

                                        ::-webkit-scrollbar-thumb {
                                            background-color: #7d7d7d; 
                                            border-radius: 3em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .admin-element-editor {
                width: 100%;
                background-color: ${variables.dpc_backgroundWhite};
                margin: 2em 0;
                padding: 2em;
                border: 1px solid ${variables.dpc_borderDark};
                

                .header-wrapper {
                    .comments-editor-header {
                        display: flex;
                        justify-content: space-between;

                        .comments-editor-header-col {
                            &.col-left {
                                display: flex;
                                color: ${variables.dpc_fontDark};
                                align-items: center;

                                .comments-editor-header-headline {
                                    font-size: 1.5em;
                                    font-weight: 300;
                                }
                            }                            
                        }                        
                    }
                }

                
                .editor-wrapper {
                    .forms-wrapper {
                        width: 100%;
                        padding-right: 0;

                        .form-wrapper {
                            border-top: 0;
                            margin-top: 0;
                            
                            .admin-form-generator {
                                .form-elements {
                                    .panel-measurements-box {
                                        width: 100%;
                                        .admin-panel-box {
                                            box-shadow: none;
                                            border: none;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .data-wrapper {
                        display: none;
                    }
                }
            }
        }

        .route-select {
            margin-top: 2em;
            
            .route-select-headline {
                font-size: 2em;
                color: ${variables.dpc_fontDark};
                font-weight: 300;
            }
            .routes {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 2em;

                .route {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border: 1px solid #666666;
                    padding: 2em;
                    min-height: 15em;
                    cursor: pointer;
                    text-align: center;
                    margin: 0 1em;
                    width: 100%;

                    .route-headline {
                        color: ${variables.dpc_fontDark};
                        font-weight: 600;
                        font-size: 1.4em;
                    }

                    .route-hint {
                        color: ${variables.dpc_fontDark};
                        font-size: 1em;
                        font-weight: 300;
                        margin-top: 1em;

                        &.route-hint-alert {
                            color: #DD3544;
                            font-weight: 600;
                        }
                    }

                    &:hover {
                        background: ${variables.dpc_backgroundLightRed};
                        border-color: #DD3544;
                    }

                    &.disabled {
                        cursor: default;
                        background: #403f3f;

                        &:hover {
                            background: #403f3f;
                            border-color: #666666;
                        }
                    }
                }
            }
        }
        .footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 1em;

            .component-button {
                width: 100%;
            }
        }

        .panel-step-accordion {
            .steps {
                .step {
                    .list-headline {
                        margin-bottom: 2em;
                        color: white;
                        font-size: 1.2em;
                        padding-top: 1em;
                        border-top: 1px solid #444;
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        .dont-force-planner {
            .details {
                .detail-item {
                    width: 100%;
                    flex-wrap: wrap;
                }

                .admin-element-editor {
                    .header-wrapper {                             
                        .comments-editor-header {
                            flex-wrap: wrap;

                            .comments-editor-header-col {    
                                &.col-left {
                                    margin-bottom: 2em;
                                }                            
                            }                        
                        }
                    }
                }
            }
        }

        .route-select {
            .routes {
                flex-wrap: wrap;
            }
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .dont-force-planner {
            .details {
                margin-bottom: 1em;
                
                .detail-item {
                    margin-bottom: 1.25em;
                    flex-wrap: wrap;

                    .label {
                        margin-right: .2em;
                        width: 100%;
                    }

                    .value {
                        margin-top: .5em;
                    }
                }

                .admin-element-editor {
                    .form-element {
                        font-size: 1.5em;

                        .input-label {
                            color: #E1E1E1 !important;
                        }
                        
                        textarea {
                            font-weight: 400 !important;
                        }
                    }
                }
            }
        }
    }
`;
