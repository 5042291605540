import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
} from 'Redux/modules/trainer/userComments/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
        },
    }),
)(Component);