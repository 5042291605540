import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import TextBox from 'Components/layout/panel/TextBox';
import Spinner from 'Components/layout/Spinner';

export default class TrainerEventsStats extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            stats: PropTypes.func.isRequired,
        }).isRequired,
        stats: PropTypes.object,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        paidStatus: undefined,
        doneStatus: undefined,
        planned: undefined,
        startAtFrom:  undefined,
        startAtTo:  undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.stats({ ...queryObject });
        }, 500);
    }

    render() {
        const { stats } = this.props;
        const { data } = stats;

        return (
            <StyledComponent
                className="trainer-events-stats"
                styles={require('./styles')}
            >
                {data && (
                    <>
                        <TextBox
                            title={data.paid.label}
                            styleVersion={2}
                            stateColor='info'
                            subtitle={data.paid.value}
                        />
                        <TextBox
                            title={data.bonus.label}
                            styleVersion={2}
                            stateColor='info'
                            subtitle={data.bonus.value}
                        />
                        <TextBox
                            title={data.done.label}
                            styleVersion={2}
                            stateColor='info'
                            subtitle={data.done.value}
                        />
                        <div className="not-visible" />
                        <TextBox
                            title={data.notPlanned.label}
                            styleVersion={2}
                            stateColor='info'
                            subtitle={data.notPlanned.value}
                        />
                    </>
                )}
            </StyledComponent>
        );
    }
}
