import { connect } from 'react-redux';
import Component from './component';

import {
    chartData,
} from 'Redux/modules/trainer/measurements/actions';
import { single as getMeasurementsConfig } from 'Redux/modules/trainer/measurementsConfig/actions';

export default connect(
    state => ({
        chartData: state.trainerMeasurements.chartData,
        measurementsConfig: state.trainerMeasurementsConfig.mapById,
    }),
    dispatch => ({
        actions: {
            chartData: dispatch(chartData),
            getMeasurementsConfig: dispatch(getMeasurementsConfig),
        },
    }),
    (stateProps, dispatchProps, ownProps) => {
        const { predefinedState } = ownProps;

        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
            measurementsConfig: stateProps.measurementsConfig[predefinedState.userId],
        };
    }
)(Component);
