import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class TrainerCancelEvent extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            cancel24h: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
        onClose: PropTypes.func,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
    };

    onSubmit = formState => {
        const { actions, data, onSuccess } = this.props;

        return actions.cancel24h({
            id: data.id,
            reason: formState.reason,
        })
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                toast.error('Musisz podać powód odwołania treningu');
                Logger.error('[TrainerCancelTrainingModal] Failed to cancel training', error);
                this.setState({
                    isPending: false,
                    errors: false,
                });

                throw error;
            });
    }

    render() {
        const { data, location, history } = this.props;
        const { formState } = this.state;
        
        if (!data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-events-cancel"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Podaj powód odwołania treningu',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'textarea',
                            name: 'reason',
                            label: 'Powód',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}