import { connect } from 'react-redux';
import Component from './component';

import { list } from 'Redux/modules/trainer/userComments/actions';

export default connect(
    state => ({
        userComments: state.trainerUserComments.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    })
)(Component);
