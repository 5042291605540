import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    color: #333333;
    width: 40vw;

    .input-label {
        color: ${variables.dpc_fontDark};
        font-size: 1.5em;
        margin-bottom: 1em;
    }
`;
