import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { API_RESOURCE_ADEPT_TEST, API_RESOURCE_USERS } from 'Consts/apiResources';
import { TRAINER_ADEPT_TESTS_MANAGE } from 'Consts/routes';
import { USER_ROLE_ADEPT } from 'Consts/userRoles';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import Button from 'Components/layout/Button';

export default class TrainerAdeptTestsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {
            ...this.props.predefinedState, //eslint-disable-line react/destructuring-assignment
        },
        products: [],
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        adeptId: data.adept
            ? { value: data.adept.id, label: getUserFullName(data.adept, 'nameSurname').label }
            : null,
        testDate: data.testDate && moment(data.testDate).toDate() || moment().toDate(),
    })

    formStateToApi = formState => {
        const { predefinedState } = this.props;
        
        return {
            ...formState,
            adeptId: fromSelectObject(formState.adeptId),
            testDate: dateToApiFormat(formState.testDate),
            ...predefinedState,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToApi(formState),
        })
            .then(response => {
                history.push(
                    withVariables(
                        TRAINER_ADEPT_TESTS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_ADEPT_TEST].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...this.formStateToApi(formState),
            id: data.id,
        });
    }

    render() {
        const { data, location, history, actions, predefinedState } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-adept-tests-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    layout="box"
                    styleVersion={2}
                    header={(
                        <div className="trainer-adept-tests-editor-header">
                            <div className="trainer-adept-tests-editor-header-col col-left">
                                <h2 className="trainer-adept-tests-editor-header-headline">
                                    Test adepta {data && data.adept && getUserFullName(data.adept, 'nameSurname').label}
                                </h2>
                            </div>
                            <div className="trainer-adept-tests-editor-header-col col-right">
                                <Button
                                    className="measurements-editor-header-button"
                                    onClick={() => this.onSubmit}
                                    type='submit'
                                    form='editor'
                                    style='hollow'
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </div>
                    )}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        submitVisible: false,
                        data: formState,
                        layout: 'box',
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'adeptId',
                            label: 'Adept',
                            isVisible: Boolean(!data && !predefinedState.adeptId),
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_ADEPT,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                                style: 'transparentV2',
                            },
                        }, {
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa testu',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/dumbbell.svg'),
                            },
                            iconColor: 'white',
                            inputProps: {
                                style: 'transparentV2',
                                border: 'light',
                            },
                            styleVersion: 2,
                        }, {
                            type: 'input',
                            name: 'rating',
                            label: 'Ocena testu',
                            iconColor: 'white',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/star.svg'),
                            },
                            inputProps: {
                                disabled: false,
                                type: 'number',
                                style: 'transparentV2',
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'testDate',
                            label: 'Data testu',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/calendar.svg'),
                            },
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                                style: 'version-2',
                                border: 'light',
                            },
                        }, {
                            type: 'textarea',
                            name: 'description',
                            label: 'Opis testu',
                            size: 'fullWidth',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}

