import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import MeasurementsProgress from 'Components/trainer/measurements/Progress';
import MeasurementsList from 'Components/trainer/measurements/List';
import MeasurementsChart from 'Components/trainer/measurements/Chart';

export default class TrainerMeasurementsDashboard extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        progress: PropTypes.object.isRequired,
        chartData: PropTypes.oneOfType([
            PropTypes.object, 
        ]),
        actions: PropTypes.shape({
            getProgress: PropTypes.func.isRequired,
            getChartData: PropTypes.func.isRequired,
        }),
    };
    static defaultProps = {
        predefinedQuery: {},
        progress: null,
        chartData: null,
    };

    componentDidMount = () => {
        const { actions, predefinedQuery } = this.props;
        const userId = predefinedQuery.userId;

        actions.getChartData({ userId });
        actions.getProgress({ userId });
    }

    render() {
        const { location, history, predefinedQuery, progress, chartData }  = this.props;

        return (
            <StyledComponent
                className="trainer-measurements-dashboard"
                styles={require('./styles')}
            >
                {chartData && Object.keys(chartData.elements).length > 0 && (
                    <section className="section section-chart">
                        <h2 className="section-headline">
                            Wykresy
                        </h2>
                        <MeasurementsChart
                            location={location} 
                            history={history}
                            predefinedState={predefinedQuery}
                        />
                    </section>
                )}
                {progress && progress.elements?.first && progress.elements?.last && (
                    <section className="section section-progress">
                        <h2 className="section-headline">
                            Progres adepta
                        </h2>
                        <MeasurementsProgress 
                            location={location} 
                            history={history}
                            predefinedState={predefinedQuery}
                        />
                    </section>
                )}
                <section className="section section-list">
                    <h2 className="section-headline">
                        Historia i edycja pomiarów
                    </h2>
                    <MeasurementsList 
                        location={location} 
                        history={history}
                        predefinedQuery={predefinedQuery}
                    />
                </section>
            </StyledComponent>
        );
    }
}
