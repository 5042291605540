import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .react-datepicker-popper {
        left: -3em !important;
    }
    
    .date-form {
        .date-picker {
            .input-external-wrapper {
                .input-label {
                    width: 100%;
                }
                .input-internal-wrapper {
                    width: 100%;

                    .react-datepicker-wrapper {
                        width: 100%;
                        
                        .react-datepicker__input-container {
                            input {
                                text-align: center;
                                font-size: 120%;
                            }
                        }
                    }
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 2em;
            }
        }

        .location-occupation {
            font-size: 1.2em;
            color: red;
        }
    }

    .event-cancel-wrapper {
        .event-cancel-description {
            color: white;
            margin: 1em 0;
        }
    }

    @media (max-width: ${variables.tabletS}) {
        .date-container {
            .date-picker {
                margin-top: .5em;

                .input-external-wrapper {
                    width: 100%;

                    .input-internal-wrapper {
                        width: 100%;

                        .react-datepicker-wrapper {
                            width: 100%;

                            input {
                                text-align: center;
                            }
                        }

                        .react-datepicker-popper {
                            width: 20em;
                        }
                    }
                }
            }
        }
    }
`;
