import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_USER_HOMEWORK_MANAGE } from 'Consts/routes';

import { getFormattedDate } from 'Utils/date';
import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElement';

export default class TrainerUserHomeworksList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            changeCompleted: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        userHomeworks: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {};

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        userId: undefined,
        ...(this.props.predefinedQuery || {}), //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ userId: queryObject.user.id });
        }, 500);
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(predefinedQuery)) {
            this.loadData();
        }
    }

    render() {
        const { location, history, userHomeworks, actions } = this.props;

        return (
            <StyledComponent
                className="trainer-user-homeworks-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userHomeworks}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.deadlineAt && getFormattedDate(element.deadlineAt) || 'Brak'}
                            controls={[{
                                type: 'button',
                                label: element.isCompleted ? 'Niewykonana' : 'Wykonana',
                                style: 'hollow',
                                onClick: () => actions.changeCompleted({ id: element.id }),
                            }, {
                                type: 'button',
                                label: 'Szczegóły',
                                visible: true,
                                to: withVariables(TRAINER_USER_HOMEWORK_MANAGE.path, { id: element?.id }),
                                style: 'hollow',
                            }]}
                            labels={[{
                                isVisible: true,
                                label: element.isCompleted
                                    ? 'Wykonana' 
                                    : 'Niewykonana',
                                state: element.isCompleted
                                    ? 'success'
                                    : 'alert',
                            }]}
                            additionals={[{
                                name: 'Utworzył',
                                value: element?.creator?.name + ' ' + element?.creator?.surname,
                            }, {
                                name: 'Trener',
                                value: element?.trainer?.name + ' ' + element?.trainer?.surname,
                            }]}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}