import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        position: fixed;
        display: block;
        bottom: 2em;
        right: 2em;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            font-size: 1em;
            font-weight: 900;
            text-decoration: none;
            cursor: pointer;

            border-radius: .3em;
            box-shadow: 0 .1em .1em rgba(0, 0, 0, 0.25);
            border: none;

            &:hover {
                box-shadow: 0 .1em .3em rgba(0, 0, 0, 0.35);
            }
        }

        .shape-circle {
            padding: .5em;
            border-radius: 360em;
        }

        .style-gradient {
            background-image: linear-gradient(to left, #DA3143, #FF6254) ;
            box-shadow: 0px 0px 5px #FF003166;
            color: #FFFFFF;
            font-weight: 500;
            border: 1px solid #DF3846;
            transition: all .3s ease-in-out;
            text-transform: uppercase;

            &:hover {
                box-shadow: 0px -.3em .1em rgba(0, 0, 0, .3) inset;
            }

            &:active {
                box-shadow: 0 .3em .1em rgba(0, 0, 0, 0.3) inset;
            }

            &.disabled {
                border: 1px solid #666666;
                color: #c29191;

                &:hover {
                    box-shadow: 0px 0px 5px #FF003166;
                }
            }
        }

        .size-medium {
            width: 4em;
            height: 4em;

            .button-icon {
                font-size: 1.5em;
            }
        }

        [disabled] {
            background-color: #909090;
        };
    `;
