import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class TrainerUserCommentsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        if (data) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
    })

    formStateToData = formState => ({
        ...formState,
    })

    onSubmit = formState => {
        const { data } = this.props;

        return data
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        });
    }

    onCreate = formState => {
        const { actions, predefinedState } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
            ...predefinedState,
        });
    }

    render() {
        const { data, location, history } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-user-comments-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data?.id),
                        label: 'id',
                        value: formState.id,
                    }, {
                        visible: Boolean(data?.creator),
                        label: 'Utworzył',
                        value: data?.creator
                            ? `${data.creator?.name} ${data.creator?.surname}`
                            : 'Brak twórcy',
                    }, {
                        visible: Boolean(data?.user),
                        label: 'Użytkownik',
                        value: data?.user
                            ? `${data.user?.name} ${data.user?.surname}`
                            : 'Brak użytkownika',
                    }]}
                    forms={[{
                        title: 'Dodaj komentarz',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'htmlEditor',
                            name: 'content',
                            label: 'Komentarz',
                            required: true,
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}

