import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { USER_ROLE_ADMIN, USER_ROLE_TRAINER } from 'Consts/userRoles';
import { ENDPOINT_TRAINER_FILE_PRESIGN } from 'Consts/api';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import UserCommentsList from 'Components/trainer/userComments/List';
import UserCommentsEditor from 'Components/trainer/userComments/Editor';

export default class TrainerNotesEditor extends Component {
    static defaultProps = {};
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            createNote: PropTypes.func.isRequired,
            getUser: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
        }),
        user: PropTypes.object,
    };
    
    state = {
        formState: {
            isPending: false,
            trainerNoteContent: '',
            adminNoteContent: '',
        },
    };

    componentDidMount = () => {
        this.dataToFormState();
    }

    dataToFormState = () => {
        const { user } = this.props;
        const { userNotes } = user;

        const trainerNoteContent = userNotes.find(note => note.type == USER_ROLE_TRAINER)?.notes || '';
        const adminNoteContent = userNotes.find(note => note.type == USER_ROLE_ADMIN)?.notes || '';
        const files = user.files.map(file => ({
            ...file,
            uploadedFile:file,
        }));
        
        const data = {
            trainerNoteContent,
            adminNoteContent,
            files,
        };

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...data,
                isPending: false,
            },
        }));
    }

    onSubmit = formState => {
        const { actions, user } = this.props;
        const { trainerNoteContent } = formState;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                isPending: true,
            },
        }));

        const data = {
            id: user.id,
            type: 'trainer',
            notes: trainerNoteContent,
            fileIds: Array.isArray(formState.files)
                ? formState.files.map(file => file.id)
                : [],
        };

        return actions.createNote(data)
            .then(() => {
                actions.getUser({ id: data.id }).then(this.dataToFormState);
                
                this.setState({
                    isPending: false,
                });
            })
            .catch(() => {
                this.setState({
                    isPending: false,
                });
            });
    }

    render() {
        const { location, history, actions, user } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="trainer-note-editor"
                styles={require('./styles')}
            >
                {formState.isPending && (
                    <Spinner />
                )}
                <div className="editor-note-container">
                    <div className="editor-main-note">
                        <ElementEditor
                            location={location}
                            history={history}
                            layout="box"
                            styleVersion={2}
                            forms={[{
                                name: 'editor',
                                data: formState,
                                layout: 'box',
                                submitVisible: true,
                                submitButtonStyle: 'hollow',
                                submitAction: this.onSubmit,
                                onStateChange: formState => {
                                    this.setState({
                                        formState,
                                    });
                                },
                                styleVersion: 2,
                                elements: [{
                                    type: 'textarea',
                                    name: 'trainerNoteContent',
                                    label: 'Notatka od trenera',
                                    size: 'fullWidth',
                                    inputProps: { 
                                        style: 'hollow',
                                        border: 'light-2', 
                                        placeholder: 'Treść notatki',
                                        styleVersion: 2,
                                    },
                                }, {
                                    type: 's3FileMultiUpload',
                                    name: 'files',
                                    label: 'Pliki',
                                    inputProps: {
                                        action: actions.presignFile,
                                        s3Config: {
                                            presignPath: ENDPOINT_TRAINER_FILE_PRESIGN
                                                .replace('{type}', 'userNotesFile'),
                                        },
                                    },
                                }],
                            }]}
                        />
                    </div>
                    <div className="comments">
                        <UserCommentsList
                            location={location}
                            history={history}
                            predefinedQuery={{
                                userId: user.id,
                            }}
                        />
                        <UserCommentsEditor
                            location={location}
                            history={history}
                            predefinedState={{
                                userId: user.id,
                            }}
                        />
                    </div>
                </div>
            </StyledComponent>
        );
    }
}