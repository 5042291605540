import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_ADEPTS_MANAGE, TRAINER_ADEPT_TESTS_CREATE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import Editor from 'Components/trainer/adeptTests/Editor';
import { TAB_TESTS } from 'Components/pages/trainer/UsersManage/component';

export default class TrainerAdeptTestsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const queryObject = parseQueryToObject(location.search);

        return (
            <StyledComponent
                className="trainer-adept-tests-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: queryObject.userId }, { tab: TAB_TESTS }),
                        label: 'Testy',
                    }, {
                        to: withVariables(TRAINER_ADEPT_TESTS_CREATE.path, {}, { userId: queryObject.userId }),
                        label: 'Nowy test',
                    }]}
                >
                    <ViewHeader
                        title="Nowy test"
                    />
                    <Editor
                        location={location}
                        history={history}
                        predefinedState={{
                            adeptId: queryObject.userId,
                        }}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}