import { connect } from 'react-redux';
import Component from './component';

import {
    cancel,
    suggestFreeSlots,
    locationOccupancy,
} from 'Redux/modules/trainer/events/actions';

export default connect(
    state => ({
        user: state.user,
    }),
    dispatch => ({
        actions: {
            cancel: dispatch(cancel),
            suggestFreeSlots: dispatch(suggestFreeSlots),
            locationOccupancy: dispatch(locationOccupancy),
        },
    }),
)(Component);