import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';    

import { resetQueryString } from 'Utils/querystring';

import { API_RESOURCE_PRODUCTS } from 'Consts/apiResources';

import StyledComponent from 'Components/core/StyledComponent';
import TextBox from 'Components/layout/panel/TextBox';

export default class TrainerEventsPlannerStepProduct extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listProducts: PropTypes.func.isRequired,
        }).isRequired,
        formState: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
    };
    static defaultProps = {};

    state = {
        products: [],
    };

    componentDidMount = () => {
        const { location, history } = this.props;

        this.setData();
        resetQueryString(location, history);
    }

    componentDidUpdate = prevProps => {
        const { formState, onChangeFormState } = this.props;

        if(formState.product && JSON.stringify(formState.product) !== JSON.stringify(prevProps.formState.product)) {
            this.setData();
        }

        if(
            formState.product && 
            formState.startAt && 
            (
                JSON.stringify(formState.product) !== JSON.stringify(prevProps.formState.product) ||
                !moment(formState.startAt).isSame(prevProps.formState.startAt)
            )
        ) {
            onChangeFormState({
                endAt: moment(formState.startAt)
                    .add(formState.product.durationType, formState.product.durationValue),
            });
        }
    }

    setData = () => {
        const { actions, formState } = this.props;

        return actions.listProducts({ 
            page: 1, 
            perPage: 9999, 
            userOfferId: formState.userOffer && formState.userOffer.id, 
        })
            .then(response => {
                const elements = response.payload[API_RESOURCE_PRODUCTS].elements;
                this.setState({ products: elements });
            });
    }   

    render() {
        const { formState, onChangeFormState } = this.props;
        const { products } = this.state;

        return (
            <StyledComponent
                className="adept-events-planner-step-product"
                styles={require('./styles')}
            >
                <h3 className="list-headline">
                    Wybierz produkt
                </h3>
                <div className="list-container">
                    {products.map(product => (
                        <a
                            key={product.id}
                            onClick={() => onChangeFormState({ product })}
                            className={formState.product && formState.product.id === product.id
                                ? 'product-link active'
                                : 'product-link'
                            }
                        >
                            <TextBox 
                                title={product.name}
                                styleVersion={3}
                            /> 
                        </a>
                    ))}
                </div>
            </StyledComponent>
        );
    }
}