import React from 'react';
import PropTypes from 'prop-types';

import { 
    TRAINER_DASHBOARD,
    TRAINER_ADEPTS, 
    TRAINER_ADEPTS_MANAGE, 
    TRAINER_ADEPT_TESTS_CREATE, 
    TRAINER_MEASUREMENTS_CREATE, 
    TRAINER_TRAINING_TARGETS_CREATE, 
    TRAINER_MEDICAL_CARD_CREATE,
    TRAINER_MEASUREMENTS_CONFIG_MANAGE,
    TRAINER_USER_HOMEWORK_CREATE,
    TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE,
    TRAINER_USER_EFFECT_CREATE,
} from 'Consts/routes';
import { DATE_DEFAULT_FORMAT } from 'Consts/date';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import EventsList from 'Components/trainer/events/List';
import EventsCalendar from 'Components/trainer/events/Calendar';
import MeasurementsDashboard from 'Components/trainer/measurements/Dashboard';
import AdeptTestsList from 'Components/trainer/adeptTests/List';
import TrainingTargetsList from 'Components/trainer/trainingTargets/List';
import MedicalDataPanel from 'Components/trainer/medical/Panel';
import EventsStats from 'Components/trainer/events/Stats';
import UserNotesEditor from 'Components/trainer/notes/Editor';
import UserOffers from 'Components/trainer/userOffers/List';
import UserSurveysList from 'Components/trainer/userSurveys/List';
import DiagnosticReportsList from 'Components/trainer/userOfferDiagnosticReports/List';
import UserHomeworksList from 'Components/trainer/userHomeworks/List';
import UserEffectsList from 'Components/trainer/userEffects/List';

export const TAB_MEASUREMENTS = 'measurements';
export const TAB_TRAINING_TARGETS = 'trainingTargets';
export const TAB_TESTS = 'tests';
export const TAB_STATS = 'statystyki';
export const TAB_USER_NOTES = 'userNotes';
export const TAB_USER_OFFERS = 'userOffers';
export const TAB_USER_SURVEYS = 'userSurveys';
export const TAB_DIAGNOSTIC_REPORTS = 'diagnosticReports';
export const TAB_USER_HOMEWORKS = 'userHomeworks';
export const TAB_USER_EFFECTS = 'userEffects';

export default class TrainerUsersManage extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }),
        user: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
            error: PropTypes.bool.isRequired,
        }),
    }
    static defaultProps = {};

    state = {
        width: 0,
        height: 0,
    }
    
    componentDidMount = () => {
        const { match, actions } = this.props;
        
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        
        return actions.single({ id: match.params.id });
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
        
    updateWindowDimensions = () => {
        this.setState({ 
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    render() {
        const { width } = this.state;
        const { history, location, user } = this.props;
        const queryObject = parseQueryToObject(location.search);

        return (
            <StyledComponent 
                className='trainer-users-manage' 
                styles={require('./styles')}
            >
                <PageContent
                    elementMode={true}
                    data={user}
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: user && user.data && user.data.id }),
                        label: `Adept: ${(user && user.data && getFullName(user.data, 'nameSurname').label)}`,
                    }]}
                >
                    {user && user.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline={`Adept: ${user.data.name} ${user.data.surname}`}
                            subHeadline={
                                <>
                                    {user.data.phone 
                                        ? <a>Telefon: <b>{user.data.phone}</b><br/></a>
                                        : null
                                    }
                                    {user.data.email
                                        ? <a>Email: <b>{user.data.email}</b><br/></a>
                                        : null
                                    }
                                    {user.data.userData
                                        ? <a>Wzrost: <b>{user.data?.userData?.height}</b><br/></a>
                                        : null
                                    }
                                    {user.data.userData
                                        ? <a>Waga: <b>{user.data?.userData?.weight}</b><br/></a>
                                        : null
                                    }
                                    {user.data.userData
                                        ? <a>Data urodzin: <b>{getFormattedDate(user.data.userData?.birthdate, DATE_DEFAULT_FORMAT)}</b><br/></a>
                                        : null
                                    }
                                    {user.data.accessCode?.code
                                        ? <a>Kod dostępu FitAdeptTV: <b>{user.data.accessCode.code}</b><br/></a>
                                        : null
                                    }
                                    {user.data.accessCode?.validTo
                                        ? <a>Data ważności kodu FitAdeptTV: <b>{getFormattedDate(user.data.accessCode.validTo)}</b></a>
                                        : null
                                    }
                                </>
                            }
                            controls={[
                                {
                                    visible: Boolean(queryObject.tab === TAB_TESTS),
                                    key: 'createTests',
                                    label: 'Nowy test',
                                    onClick: () => history.push(
                                        withVariables(
                                            TRAINER_ADEPT_TESTS_CREATE.path,
                                            {},
                                            { userId: user.data.id },
                                        )
                                    ),
                                    style: 'gradient',
                                }, {
                                    visible: Boolean(queryObject.tab === TAB_MEASUREMENTS),
                                    key: 'createMeasurements',
                                    label: 'Dodaj nowy pomiar',
                                    onClick: () => history.push(
                                        withVariables(
                                            TRAINER_MEASUREMENTS_CREATE.path,
                                            {},
                                            { userId: user.data.id },
                                        )
                                    ),
                                    style: 'gradient',
                                    layout: 'wide',
                                }, {
                                    visible: Boolean(queryObject.tab === TAB_MEASUREMENTS),
                                    key: 'createMeasurementConfig',
                                    label: 'Ustawienia pomiarów',
                                    onClick: () => history.push(
                                        withVariables(
                                            TRAINER_MEASUREMENTS_CONFIG_MANAGE.path,
                                            {},
                                            { userId: user.data.id },
                                        )
                                    ),
                                    style: 'gradient',
                                    layout: 'wide',
                                }, {
                                    visible: Boolean(queryObject.tab === TAB_TRAINING_TARGETS),
                                    key: 'createTrainingTargets',
                                    label: 'Dodaj nowy cel treningowy',
                                    onClick: () => history.push(
                                        withVariables(
                                            TRAINER_TRAINING_TARGETS_CREATE.path,
                                            {},
                                            { userId: user.data.id },
                                        )
                                    ),
                                    style: 'gradient',
                                    layout: 'wide',
                                }, {
                                    visible: Boolean(queryObject.tab === 'medicalData'),
                                    key: 'createMedicalData',
                                    label: 'Dodaj nowy wywiad medyczny',
                                    onClick: () => history.push(
                                        withVariables(
                                            TRAINER_MEDICAL_CARD_CREATE.path,
                                            {},
                                            { userId: user.data.id },
                                        )
                                    ),
                                    style: 'gradient',
                                    layout: 'wide',
                                }, {
                                    visible: Boolean(queryObject.tab === TAB_USER_HOMEWORKS),
                                    key: 'createUserHomework',
                                    label: 'Nowa praca domowa',
                                    onClick: () => history.push(
                                        withVariables(
                                            TRAINER_USER_HOMEWORK_CREATE.path,
                                            {},
                                            { userId: user.data.id },
                                        )
                                    ),
                                    style: 'gradient',
                                    layout: 'wide',
                                }, {
                                    visible: Boolean(queryObject.tab === 'diagnosticReports'),
                                    key: 'userOfferDiagnosticReport',
                                    label: 'Nowy raport diagnostyczny',
                                    onClick: () => history.push(
                                        withVariables(
                                            TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE.path,
                                            {},
                                            { userId: user.data.id },
                                        )
                                    ),
                                    style: 'gradient',
                                    layout: 'wide',
                                }, {
                                    visible: Boolean(queryObject.tab === 'userEffects'),
                                    key: 'userEffectCreate',
                                    label: 'Nowy efekt',
                                    onClick: () => history.push(
                                        withVariables(
                                            TRAINER_USER_EFFECT_CREATE.path,
                                            {},
                                            { userId: user.data.id },
                                        )
                                    ),
                                    style: 'gradient',
                                    layout: 'wide',
                                }
                            ]}
                            tabs={[{
                                key: 'eventsList',
                                label: 'Lista wydarzeń',
                                children: (
                                    <EventsList 
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                            orderBy: 'createdAt',
                                        }}
                                        allAdeptEvents={false}
                                    />
                                ),
                            }, {
                                key: TAB_USER_NOTES,
                                label: 'Notatki',
                                visible: true,
                                children: (
                                    <UserNotesEditor
                                        location={location}
                                        history={history}
                                        user={user.data}
                                    />
                                ),
                            }, {
                                key: TAB_USER_HOMEWORKS,
                                label: 'Prace domowe',
                                children: (
                                    <UserHomeworksList 
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            user: user.data,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_MEASUREMENTS,
                                label: 'Pomiary',
                                children: (
                                    <MeasurementsDashboard
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_TRAINING_TARGETS,
                                label: 'Cele treningowe',
                                children: (
                                    <TrainingTargetsList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_DIAGNOSTIC_REPORTS,
                                label: 'Raporty diagnostyczne',
                                children: (
                                    <DiagnosticReportsList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: 'medicalData',
                                label: 'Wywiad medyczny',
                                children: (
                                    <MedicalDataPanel
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_USER_SURVEYS,
                                label: 'Ankiety',
                                visible: true,
                                children: (
                                    <UserSurveysList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_STATS,
                                label: 'Statystyki',
                                visible: true,
                                children: (
                                    <EventsStats
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_USER_OFFERS,
                                label: 'Zamówienia',
                                visible: true,
                                children: (
                                    <UserOffers
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: 'eventsCalendar',
                                label: 'Kalendarz wydarzeń',
                                children: (
                                    <EventsCalendar 
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            user: user.data,
                                        }}
                                        predefinedState={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_TESTS,
                                label: 'Testy',
                                visible: false,
                                children: (
                                    <AdeptTestsList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_USER_EFFECTS,
                                label: 'Efekty',
                                visible: true,
                                children: (
                                    <UserEffectsList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            userId: user.data.id,
                                        }}
                                    />
                                ),
                            }]}
                        />
                    )}
                </PageContent>
            </StyledComponent>
        );
    }
}