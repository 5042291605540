import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { OFFER_TYPE_DIAGNOSTIC } from 'Consts/offers';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layout/modals/ModalWrapper';
import ModalBody from 'Components/layout/modals/ModalBody';
import ModalTitle from 'Components/layout/modals/ModalTitle';
import Cancel from 'Components/trainer/events/Cancel';
import CancelDiagnostic from 'Components/trainer/events/CancelDiagnostic';

export default class TrainerModalCancelEvent extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        data: PropTypes.object,
        onSuccess: PropTypes.func,
        title: PropTypes.string,
    };
    static defaultProps = {
        isOpen: false,
        onClose: () => {},
        data: null,
        onSuccess: () => {},
    };
    state = {};

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        return parseQueryToObject(search, true);
    }

    render() {
        const { location, history, isOpen, onClose, data, onSuccess, title } = this.props;
        const queryObject = this.getQueryConfig();

        return (
            <StyledComponent className="trainer-modal-cancel-event" styles={require('./styles')}>
                <ModalWrapper
                    location={location}
                    history={history}
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <ModalBody>
                        <ModalTitle title={title} />
                        {
                            data?.offer?.type === OFFER_TYPE_DIAGNOSTIC
                                ? (
                                    <CancelDiagnostic
                                        location={location}
                                        history={history}
                                        data={data}
                                        onSuccess={onSuccess}
                                    />
                                )
                                : (
                                    <Cancel
                                        location={location}
                                        history={history}
                                        data={data}
                                        onConfirm={onClose}
                                        eventId={queryObject.eventId}
                                        onSuccess={onSuccess}
                                    />
                                )
                        }
                    </ModalBody>
                </ModalWrapper>
            </StyledComponent>
        );
    }
}
