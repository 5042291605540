import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';    

import { API_RESOURCE_EVENTS_FREE_SLOTS, API_RESOURCE_LOCATION_OCCUPANCY } from 'Consts/apiResources';
import { EVENT_CATEGORY_PRIVATE, PLAN_LIMIT_TRAINER_TYPE, PLAN_LIMIT_TRAINER_VALUE, EVENT_CATEGORY_DEFAULT } from 'Consts/events';
import { LOCATION_TYPE_ONLINE } from 'Consts/locations';
import { TRAINER_PLANNER_TIME_INTERVALS } from 'Consts/date';

import { toApiFormat as dateToApiFormat, getFreeTimeDateRange } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import DatePicker from 'Components/forms/DatePicker';
import Input from 'Components/forms/Input';

export default class TrainerEventsPlannerStepTime extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            cancel: PropTypes.func.isRequired,
            suggestFreeSlots: PropTypes.func.isRequired,
            locationOccupancy: PropTypes.func.isRequired,
        }).isRequired,
        formState: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    }; 

    state = {
        startAtIncludedDates: [],
        endAtIncludedDates: [],
        enableTimeValidation: false,
        datePickerDateValidation: false,
        locationEventsCount: null,
        minStartTime: moment().isSame(this.props.formState.startAt, 'day') ? moment().toDate() : undefined,
        maxStartTime: moment().isSame(this.props.formState.startAt, 'day') ? moment().endOf('day').toDate() : undefined,
        minEndTime:  moment().isSame(this.props.formState.startAt, 'day') ? moment().toDate() : undefined,
        maxEndTime: moment().isSame(this.props.formState.startAt, 'day') ? moment().endOf('day').toDate() : undefined,
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { formState, onChangeFormState, actions } = this.props;
        const { enableTimeValidation } = this.state;

        // Set endAt accroding to product duration
        if(
            formState.product && 
            formState.startAt && 
            (
                JSON.stringify(formState.product) !== JSON.stringify(prevProps.formState.product) ||
                !moment(formState.startAt).isSame(prevProps.formState.startAt)
            )
        ) {
            onChangeFormState({
                endAt: moment(formState.startAt)
                    .add(formState.product.durationType, formState.product.durationValue),
            });

            actions.locationOccupancy({
                startAt: dateToApiFormat(formState.startAt, 'datetime', true),
                durationType: formState.product.durationType,
                durationValue: formState.product.durationValue,
                locationId: formState.location.id
            })
            .then(response => {
                const result = response.payload[API_RESOURCE_LOCATION_OCCUPANCY];

                this.setState({ locationEventsCount: result });
            });
        }

        // Default event
        if (
            enableTimeValidation &&
            !moment(formState.startAt).isSame(prevProps.formState.startAt) &&
            this.isEventDefault()
        ) {
            actions.suggestFreeSlots({
                duration: formState.product && formState.product.durationValue || TRAINER_PLANNER_TIME_INTERVALS,
                startAt: dateToApiFormat(moment(formState.startAt).startOf('day')),
                endAt: dateToApiFormat(moment(formState.startAt).endOf('day')),
                userId: formState.user && formState.user.id,
                locationId: formState.location && formState.location.id,
            }).then(response => {
                this.setFreeTimeDateRange(response, 'startAt');
            });
        }

        // Private event
        // includedDates for startAt
        if (
            enableTimeValidation &&
            !moment(formState.startAt).isSame(prevProps.formState.startAt) &&
            this.isEventPrivate()
        ) {
            actions.suggestFreeSlots({
                duration: formState.product && formState.product.durationValue || TRAINER_PLANNER_TIME_INTERVALS,
                startAt: dateToApiFormat(moment(formState.startAt).startOf('day')),
                endAt: dateToApiFormat(moment(formState.startAt).endOf('day')),
            }).then(response => {
                this.setFreeTimeDateRange(response, 'startAt');
            });
        }

        // includedDates for endAt 
        if (
            enableTimeValidation &&
            (!moment(formState.startAt).isSame(prevProps.formState.startAt) || !moment(formState.endAt).isSame(prevProps.formState.endAt)) &&
            this.isEventPrivate()
        ) {
            actions.suggestFreeSlots({
                duration: formState.product && formState.product.durationValue || TRAINER_PLANNER_TIME_INTERVALS,
                startAt: dateToApiFormat(moment(formState.startAt).startOf('hour')),
                endAt: dateToApiFormat(moment(formState.endAt || formState.startAt).endOf('day')),
                locationId: formState.location && formState.location.id || null,
            }).then(response => {
                this.setFreeTimeDateRange(response, 'endAt');
            });
        }
    }

    componentDidMount = () => {
        const { formState, actions } = this.props;
        const { enableTimeValidation } = this.state;

        // Disable time
        if (!enableTimeValidation) {
            this.setState({
                startAtIncludedDates: undefined,
                endAtIncludedDates: undefined,
            });
        }

        // Default event, suggest today's free slots by default
        if (this.isEventDefault() && enableTimeValidation) {
            actions.suggestFreeSlots({
                duration: formState.product && formState.product.durationValue || TRAINER_PLANNER_TIME_INTERVALS,
                startAt: dateToApiFormat(moment().startOf('hour')),
                endAt: dateToApiFormat(moment().endOf('day')),
                userId: formState.user && formState.user.id,
                locationId: formState.location && formState.location.id,
            }).then(response => {
                this.setFreeTimeDateRange(response, 'startAt');
            });
        }

        // Private event, suggest today's free slots by default 
        if (this.isEventPrivate() && enableTimeValidation) {
            actions.suggestFreeSlots({
                duration: formState.product && formState.product.durationValue || TRAINER_PLANNER_TIME_INTERVALS,
                startAt: dateToApiFormat(moment().startOf('hour')),
                endAt: dateToApiFormat(moment().endOf('day')),
            }).then(response => {
                this.setFreeTimeDateRange(response, 'startAt');
                this.setFreeTimeDateRange(response, 'endAt');
            });
        }

        if (
            formState.startAt
            && formState.product
        ) {
            actions.locationOccupancy({
                startAt: dateToApiFormat(formState.startAt),
                durationType: formState.product.durationType,
                durationValue: formState.product.durationValue,
                locationId: formState.location.id
            })
            .then(response => {
                const result = response.payload[API_RESOURCE_LOCATION_OCCUPANCY];

                this.setState({ locationEventsCount: result });
            });
        }
    }

    setFreeTimeDateRange = (response, name, forceObject) => {
        const { formState } = this.props;

        let result = response;

        if (!forceObject) {
            result = response.payload[API_RESOURCE_EVENTS_FREE_SLOTS];
        }

        const includedDates = getFreeTimeDateRange(
            result,
            'minutes',   
            TRAINER_PLANNER_TIME_INTERVALS,    
        );
        
        if (name == 'startAt') {
            this.setState({
                startAtIncludedDates: includedDates,
            });
        }

        if (name == 'endAt') {
            let dates = includedDates;

            const foundIndex = includedDates.findIndex((date, index, array) => {
                return moment(date).diff(array[index + 1], 'minutes') < -TRAINER_PLANNER_TIME_INTERVALS;
            });

            if (foundIndex !== -1) {
                dates = includedDates.slice(0, foundIndex + 1) 
                    .filter(day => 
                        moment(day).isSame(moment(formState.endAt).toDate(), 'day')
                    );
            }

            this.setState({
                endAtIncludedDates: dates,
            });
        }
    }

    isEventDefault = () => {
        const { formState } = this.props;

        return formState.category == EVENT_CATEGORY_DEFAULT;
    }

    isEventPrivate = () => {
        const { formState } = this.props;

        return formState.category == EVENT_CATEGORY_PRIVATE;
    }

    render() {
        const { formState, onChangeFormState, mobileView } = this.props;
        const { 
            startAtIncludedDates, 
            endAtIncludedDates, 
            locationEventsCount, 
            minStartTime, 
            maxStartTime, 
            minEndTime, 
            maxEndTime 
        } = this.state;

        return (
            <StyledComponent
                className="trainer-events-planner-step-time"
                styles={require('./styles')}
            >
                <div className="date-container">
                    <h3 className="list-headline">
                        Wybierz termin
                    </h3>
                    <form
                        className="date-form" 
                        autoComplete='off'
                    >
                        {formState.category == EVENT_CATEGORY_PRIVATE && (
                            <Input 
                                name="name"
                                label="Nazwa wydarzenia"
                                value={formState.name}
                                onChange={(name) => onChangeFormState({ name: name.value })}
                                styleVersion={2}
                                style='transparentV2'
                                border='light'
                            />
                        )}
                        <DatePicker 
                            name="startAt"
                            label="Data rozpoczęcia"
                            value={formState.startAt}
                            onChange={({ value }) => {
                                onChangeFormState({ 
                                    startAt: moment(value),
                                });

                                if (moment(value)) {
                                    if (!moment().isSame(moment(value), 'day')) {
                                        this.setState({
                                            minStartTime: undefined,
                                            maxStartTime: undefined,
                                        })
                                    } else {
                                        this.setState({
                                            minStartTime: moment().toDate(),
                                            maxStartTime: moment().endOf('day').toDate(),
                                        })
                                    }
                                }
                            }}
                            border='light-2'
                            styleVersion={2}
                            datePickerProps={{
                                minDate: moment().toDate(),
                                maxDate: moment().add(PLAN_LIMIT_TRAINER_VALUE, PLAN_LIMIT_TRAINER_TYPE).toDate(),
                                minTime: minStartTime,
                                maxTime: maxStartTime,
                                dateFormat: 'yyyy-MM-dd HH:mm',
                                showTimeSelect: true,
                                disabled: formState.hasOwnProperty('canMove') && Boolean(formState && !formState.canMove), 
                                timeIntervals: TRAINER_PLANNER_TIME_INTERVALS,
                                includeTimes: startAtIncludedDates,
                            }}
                            mobileView={mobileView}
                        />
                        {!this.isEventDefault() && (
                            <DatePicker 
                                name="endAt"
                                label="Data zakończenia"
                                value={formState.endAt}
                                onChange={({ value }) => {
                                    onChangeFormState({ 
                                        endAt: moment(value),
                                    });

                                    if (moment(value)) {
                                        if (!moment().isSame(moment(value), 'day')) {
                                            this.setState({
                                                minEndTime: undefined,
                                                maxEndTime: undefined,
                                            })
                                        } else {
                                            this.setState({
                                                minEndTime: moment().toDate(),
                                                maxEndTime: moment().endOf('day').toDate(),
                                            })
                                        }
                                    }
                                }}
                                border='light-2'
                                styleVersion={2}
                                datePickerProps={{
                                    minDate: moment().toDate(),
                                    maxDate: moment().add(PLAN_LIMIT_TRAINER_VALUE, PLAN_LIMIT_TRAINER_TYPE).toDate(),
                                    minTime: minEndTime,
                                    maxTime: maxEndTime,
                                    dateFormat: 'yyyy-MM-dd HH:mm',
                                    showTimeSelect: true,
                                    disabled: formState.hasOwnProperty('canMove') && Boolean(formState && !formState.canMove), 
                                    timeIntervals: TRAINER_PLANNER_TIME_INTERVALS,
                                    includeTimes: endAtIncludedDates,
                                }}
                                mobileView={mobileView}
                            />
                        )}
                        {locationEventsCount > 0 && formState.location.type !== LOCATION_TYPE_ONLINE && (
                            <div className="location-occupation">
                                Liczba wydarzeń w wybranej lokacji o wybranym czasie: {locationEventsCount}
                            </div>
                        )}
                    </form>
                </div>
            </StyledComponent>
        );
    }
}