import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { resetQueryString } from 'Utils/querystring';
import { USER_OFFER_STATUS_ACTIVE } from 'Consts/userOffers';

import StyledComponent from 'Components/core/StyledComponent';
import UsersList from 'Components/trainer/users/List';

export default class TrainerEventsPlannerStepUser extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        formState: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
    };
    static defaultProps = {};

    componentDidMount = () => {
        const { location, history } = this.props;

        resetQueryString(location, history);
    }

    componentDidUpdate = prevProps => {
        const { formState, onChangeFormState } = this.props;

        //Set endAt accroding to product duration
        if(
            formState.product && 
            formState.startAt && 
            (
                JSON.stringify(formState.product) !== JSON.stringify(prevProps.formState.product) ||
                !moment(formState.startAt).isSame(prevProps.formState.startAt)
            )
        ) {
            onChangeFormState({
                endAt: moment(formState.startAt)
                    .add(formState.product.durationType, formState.product.durationValue),
            });
        }
    }

    render() {
        const { location, history, onChangeFormState } = this.props;
        
        return (
            <StyledComponent
                className="trainer-events-planner-step-user"
                styles={require('./styles')}
            >
                <h3 className="list-headline">
                    Wybierz adepta
                </h3>
                <UsersList 
                    location={location}
                    history={history}
                    predefinedQuery={{
                        perPage: 4,
                        orderBy: 'offerType',
                    }}
                    onMapControls={element => [{
                        type: 'button',
                        label: 'Wybierz',
                        visible: true,
                        onClick: () => onChangeFormState({ user: element }),
                        style: 'hollow',
                    }]}
                />
            </StyledComponent>
        );
    }
}