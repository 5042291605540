import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .trainer-events-list-wrapper {
        width: 100%;
    }
    

    .list-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .day-group {
        color: ${variables.dpc_fontDark};
        font-size: 1.4em;
        font-weight: 300;
        margin-top: 1em;
        border-top: 1px dashed #666666;
        padding: 1em;
    }

    @media (max-width: ${variables.tabletL}) {
        .list-header {
            flex-direction: column;

            .admin-panel-text-box {
                width: 100%;
            }
            .controls {
                margin-top: 1em;
            }
        }
    }
`;
