import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElement';
import UserCommentModal from 'Components/trainer/modals/UserCommentEditor';

export default class TrainerUserCommentsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        userComments: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
    };

    state = {
        isModalUserCommentVisible: false,
        currentComment: null,
    };
    
    static defaultProps = {
        predefinedQuery: {},
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapTitle: value => value,
        onMapSubTitle: value => value,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        userId: undefined,
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}), //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const {
            userComments,
            location,
            history,
            onMapControls,
            onMapFilters,
            onMapLabels,
            onMapTitle,
            onMapSubTitle,
        } = this.props;
        const { currentComment, isModalUserCommentVisible } = this.state;

        return (
            <StyledComponent
                className="trainer-user-comments-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userComments}
                    title="Lista komentarzy"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={onMapTitle(element.creator && `${element.creator?.name} ${element.creator?.surname}` || 'Brak twórcy')}
                            subtitle={onMapSubTitle(element.content || 'Brak komentarza')}
                            labels={onMapLabels(element, [])}
                            controls={onMapControls(element, [{
                                type: 'button',
                                style: 'gradient',
                                label: 'Edytuj',
                                onClick: () => this.setState({ isModalUserCommentVisible: true, currentComment: element }),
                            }])}
                            additionals={[{
                                name: 'Data utworzenia',
                                value: getFormattedDate(element.createdAt),
                                style: 'light',
                            }]}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }])}
                />
                <UserCommentModal
                    location={location}
                    history={history}
                    isOpen={isModalUserCommentVisible}
                    onClose={() => this.setState({ isModalUserCommentVisible: false })}
                    onSuccess={() => this.setState({ isModalUserCommentVisible: false })}
                    data={currentComment}
                />
            </StyledComponent>
        );
    }
}
