import { connect } from 'react-redux';
import Component from './component';

import {
    cancelDiagnostic,
} from 'Redux/modules/trainer/events/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            cancelDiagnostic: dispatch(cancelDiagnostic),
        },
    }),
)(Component);