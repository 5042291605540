import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_ADEPTS_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getActiveStateLabel } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class TrainerUsersList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        users: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
            meta: PropTypes.object,
        }).isRequired,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapClassName: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapImage: PropTypes.func,
        onMapType: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapClassName:  (elem, value) => value,
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapImage: (elem, value) => value,
        onMapType: (elem, value) => value,
        onMapTitle: (elem, value) => value,
        onMapSubTitle: (elem, value) => value,
    };

    defaultQuery = {
        page: 1,
        search: '',
        offerType: undefined,
        perPage: 10,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;

        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(predefinedQuery) !== JSON.stringify(prevProps.predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, predefinedQuery } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject, ...predefinedQuery });
        }, 500);
    }

    render() {
        const { 
            users, 
            location, 
            history,
            onMapClassName,
            onMapControls,
            onMapAdditionals,
            onMapFilters,
            onMapLabels,
            onMapType,
            onMapImage,
            onMapTitle,
            onMapSubTitle,
        } = this.props;

        return (
            <StyledComponent
                className="trainer-users-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={users}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            className={onMapClassName(element, '')}
                            type={onMapType(element, undefined)}
                            image={onMapImage(element, undefined)}
                            title={onMapTitle(element, `${element.name} ${element.surname}`)}
                            subtitle={onMapSubTitle(element, undefined)}
                            additionals={onMapAdditionals(element, [])}
                            labels={onMapLabels(element,[{
                                isVisible: true,
                                label: getActiveStateLabel(element).label,
                                state: getActiveStateLabel(element).stateColor,
                            }])}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: element.id }),
                                style: 'gradient',
                            }])}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'search',
                        label: '',
                        placeholder: 'Szukaj',
                        type: 'text',
                        styleProps: {
                            styleVersion: 2,
                            border: 'bottom-light',
                        }, 
                    }])}
                />
            </StyledComponent>
        );
    }
}
