import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import { EVENT_CATEGORY_PRIVATE, EVENT_CATEGORY_DEFAULT  } from 'Consts/events';
import { BIG_CALENDAR_TRAINER_DEFAULT_VIEW, EVENT_EMITTER_STICKY_TOOLBAR_PAGE_Y_OFFSET } from 'Consts/bigCalendar';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFullName } from 'Utils/user';
import { getCategoryLabel, getCalendarEventStatus } from 'Utils/event';

import {
    TIME_API_FORMAT,
} from 'Consts/date';

import StyledComponent from 'Components/core/StyledComponent';
import BigCalendar from 'Components/layout/panel/BigCalendar';
import ModalEventEditor from 'Components/trainer/modals/EventsPlanner';
import FloatButton from 'Components/layout/FloatButton';
import Spinner from 'Components/layout/Spinner';

const CustomEvent = (event) => { 
    return ( 
        <div className={`custom-event ${event.event.stateColor}`}> 
            <strong> {event.title} </strong>
        </div> 
    );
};

function TimeSlotWrapper ({ children, value, openTime }) {
    const slotTime = moment(value).format('HH:mm');
    const slotWeekDay = moment(value).isoWeekday();

    const weekDayToOpenTime = [
        ['mondayStart', 'mondayEnd'],
        ['tuesdayStart', 'tuesdayEnd'],
        ['wednesdayStart', 'wednesdayEnd'],
        ['thursdayStart', 'thursdayEnd'],
        ['fridayStart', 'fridayEnd'],
        ['saturdayStart', 'saturdayEnd'],
        ['sundayStart', 'sundayEnd'],
    ];
    let isClosed = false;
    if(openTime) {
        const currentOpenTimeKeys = weekDayToOpenTime[slotWeekDay-1];
        const currentOpenTimeFrom = moment.utc(openTime[currentOpenTimeKeys[0]], TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        const currentOpenTimeTo = moment.utc(openTime[currentOpenTimeKeys[1]], TIME_API_FORMAT).local().format(TIME_API_FORMAT);

        if (currentOpenTimeFrom && currentOpenTimeTo) {
            isClosed = (
                moment(slotTime, 'HH:mm').isBefore(moment(currentOpenTimeFrom, 'HH:mm'))
                || moment(slotTime, 'HH:mm').isSameOrAfter(moment(currentOpenTimeTo, 'HH:mm'))
            );
        }
    }
    return (
        <div className={classnames(
            'time-slot-wrapper-custom',
            { closed: isClosed }
        )}>
            {children}
        </div>
    )
}

function TimeGutterHeader ({ children, value, expanded, onToggleExpand }) {

    return (
        <div 
            className={classnames(
                'time-gutter-header-custom',
            )}
            onClick={onToggleExpand}
        >
            {expanded ? 'Mniej' : 'Więcej'}
        </div>
    )
}

export default class TrainerEventsCalendar extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        events: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        predefinedState: PropTypes.object,
        userWorkHours: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
        predefinedState: {},
    };

    defaultQuery = {
        page: 1,
        perPage: 999,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    state = {
        selectedEvent: null,
        selectedSlot: null,
        calendarDate: undefined,
        calendarView: BIG_CALENDAR_TRAINER_DEFAULT_VIEW,
        openModalEventEditor: false,
        width: 0,
        pageYOffset: 0,
        isTimeHeaderPinnedToToolbar: false,
        calendarMinifiedViewInitialized: false,
        calendarExpanded: false,
    };

    calendarRef = React.createRef();

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);
        
        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { location, events } = this.props;
        const { search } = location;

        this.setState({
            calendarDate: moment().toDate(),
        });

        if (search) {
            this.setState({
                calendarDate: moment(parseQueryToObject(search, true).date).toDate(),
            });
        }

        this.loadData();
        this.updateWindowDimensions();
        
        setTimeout(() => {
            this.renderWeekViewTimeHeader();
        }, 0);

        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.updateScrollPosition);
    }

    componentDidUpdate = (prevProps, prevState) => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
        
        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }

        if (prevState.calendarDate !== this.state.calendarDate || prevState.calendarView !== this.state.calendarView) { //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }
    }

    updateWindowDimensions = () => {
        this.setState({ 
            width: window.innerWidth,
            height: window.innerHeight,
        }, () => {
            if (window.innerWidth > 0) {
                this.setState({
                    calendarView: this.calendarRef.current.calendarRef.current.props.view,
                });
            }
        });
    }

    updateScrollPosition = () => {
        this.setState({
            pageYOffset: window.pageYOffset,
        }, () => {
            this.updateTimeHeaderPosition();
        });
    }

    renderWeekViewTimeHeader = (create = true) => {
        const { calendarView } = this.state;

        // Time view time header
        if (calendarView == 'week') {
            if (create) {
                const timeViewTimeHeader = document.querySelector('.rbc-time-view > .rbc-time-header'); // Pick time header in time view
                const timeViewTimeHeaderDayNames = timeViewTimeHeader && timeViewTimeHeader.cloneNode(true); // Clone time header from time view 

                if (timeViewTimeHeader) {
                    timeViewTimeHeaderDayNames.classList.add('day-names'); // Add class list to cloned time header
                    
                    if (timeViewTimeHeaderDayNames.querySelector('.rbc-time-header-content > .rbc-allday-cell')) {
                        timeViewTimeHeaderDayNames.querySelector('.rbc-time-header-content > .rbc-allday-cell').remove(); // Remove .allday-cell from cloned time header
                    }
        
                    timeViewTimeHeader.before(timeViewTimeHeaderDayNames); // Put cloned time header before the original one
            
                    const toolBarTimeHeaderDayNameDayNodes = timeViewTimeHeaderDayNames.querySelectorAll('.rbc-header'); // Clone nodes with class name '.rbc-header' from cloned time header 
            
                    // Sets day names
                    if (toolBarTimeHeaderDayNameDayNodes.length == 7) {
                        toolBarTimeHeaderDayNameDayNodes[0].innerHTML = 'pon';
                        toolBarTimeHeaderDayNameDayNodes[1].innerHTML = 'wt';
                        toolBarTimeHeaderDayNameDayNodes[2].innerHTML = 'śrd';
                        toolBarTimeHeaderDayNameDayNodes[3].innerHTML = 'czw';
                        toolBarTimeHeaderDayNameDayNodes[4].innerHTML = 'pt';
                        toolBarTimeHeaderDayNameDayNodes[5].innerHTML = 'sob';
                        toolBarTimeHeaderDayNameDayNodes[6].innerHTML = 'ndz';
                    }
            
                    // Toolbar 
                    const toolBar = document.querySelector('.rbc-toolbar'); // Pick toolbar
                    const toolBarTimeHeader = timeViewTimeHeader.cloneNode(true); // Clones picked toolbar
                    
                    if (toolBarTimeHeader.querySelector('.rbc-time-header-content > .rbc-allday-cell')) {
                        toolBarTimeHeader.querySelector('.rbc-time-header-content > .rbc-allday-cell').remove(); // Remove .allday-cell from cloned toolbar
                    }
        
                    const toolBarTimeHeaderDayNames = timeViewTimeHeaderDayNames.cloneNode(true); // Clones cloned time header from time view
                    const toolBarTimeHeaderContainer = document.createElement('div'); // Create div that will be added to toolbar
                    toolBarTimeHeaderContainer.classList.add('toolbar-time-header-container'); 
                    toolBarTimeHeaderContainer.appendChild(toolBarTimeHeaderDayNames); // Add 
                    toolBarTimeHeaderContainer.appendChild(toolBarTimeHeader);
                    toolBar.appendChild(toolBarTimeHeaderContainer);
                }
            } else {
                const defaultTimeHeader = document.querySelector('.rbc-calendar > .rbc-time-view > div:nth-child(2) > .rbc-time-header-content'); // Numbered days only
                if (defaultTimeHeader) {
                    setTimeout(() => {
                        const updatedToolbarTimeHeader = defaultTimeHeader.cloneNode(true);
                        updatedToolbarTimeHeader.querySelector('.rbc-allday-cell').remove();
                        const obsoleteToolbarTimeHeader = document.querySelector('.rbc-calendar > .rbc-toolbar > .toolbar-time-header-container > div:nth-child(2) > .rbc-time-header-content');
                        const toolBar = document.querySelector('.toolbar-time-header-container > div:nth-child(2)'); // Pick toolbar time header container
                        
                        if (obsoleteToolbarTimeHeader) {
                            obsoleteToolbarTimeHeader.remove();
                        }
    
                        toolBar.appendChild(updatedToolbarTimeHeader);
                    }, 0);
                }
            }
        }
    }

    updateTimeHeaderPosition = () => {
        const { isTimeHeaderPinnedToToolbar, width, pageYOffset, calendarView } = this.state;
        const toolBarTimeHeader = document.querySelector('.rbc-toolbar > .toolbar-time-header-container');

        let visibleClass = 'visible';
        width >= 1360 ? visibleClass = 'visible' : visibleClass = 'visible-mobile';

        if (calendarView == 'week' && toolBarTimeHeader) { // If isn't created, then it means, that the calendar's view is not a week.
            if (pageYOffset >= EVENT_EMITTER_STICKY_TOOLBAR_PAGE_Y_OFFSET && !isTimeHeaderPinnedToToolbar) {
                toolBarTimeHeader.classList.add(visibleClass); 
                this.setState({
                    isTimeHeaderPinnedToToolbar: true,
                });    
            }
    
            if (pageYOffset < EVENT_EMITTER_STICKY_TOOLBAR_PAGE_Y_OFFSET && isTimeHeaderPinnedToToolbar) {
                toolBarTimeHeader.classList.remove(visibleClass);
                this.setState({
                    isTimeHeaderPinnedToToolbar: false,
                });
            }
        }

        if (calendarView !== 'week' && toolBarTimeHeader) {
            toolBarTimeHeader.classList.remove(visibleClass);
            this.setState({
                isTimeHeaderPinnedToToolbar: false,
            });
        }
    };

    renderEventsLoadingOverlay = () => {
        const { events } = this.props;
        const calendarTimeView = document.querySelector('.rbc-calendar > .rbc-time-view');
        let loadingOverlay = document.querySelector('.rbc-calendar > .rbc-time-view > .overlay');

        if (!document.querySelector('.rbc-calendar > .rbc-time-view > .overlay')) {
            loadingOverlay = document.createElement('div');
            loadingOverlay.classList.add('overlay');
            calendarTimeView && calendarTimeView.appendChild(loadingOverlay);
        }

        if (loadingOverlay) {
            if (events && Boolean(events.isLoading) && Boolean(!events.isLoaded)) {
                loadingOverlay.classList.add('visible');
            }
    
            if (events && Boolean(!events.isLoading) && Boolean(events.isLoaded)) {
                loadingOverlay.classList.remove('visible');
            }
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('scroll', this.updateScrollPosition);
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();
        const { calendarDate, calendarView } = this.state;
        
        const eventsQuery = this.getEvents(calendarView, calendarDate);

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ 
                ...queryObject,
                ...eventsQuery,
            });
        }, 500);
    }

    getEvents = (view, date) => {
        switch (view) {
            case 'day':
                return {
                    fromDate: moment(date)
                        .startOf('day')
                        .toDate(),
                    untilDate: moment(date)
                        .endOf('day')
                        .toDate(),
                };
            case 'week': 
                return {
                    fromDate: moment(date)
                        .startOf('week')
                        .toDate(),
                    untilDate: moment(date)
                        .endOf('week')
                        .toDate(),
                };
            case 'month':
                return {
                    fromDate: moment(date).startOf('month')
                        .toDate(),
                    untilDate: moment(date).endOf('month')
                        .toDate(),
                };
        }
    } 

    getStateColor = (event) => {
        const { predefinedState } = this.props;

        return predefinedState?.userId && event?.user?.id !== predefinedState?.userId 
            ? 'foreign' 
            : getCalendarEventStatus(event).stateColor;
    }
    
    render() {
        const { location, history, events, user, predefinedQuery } = this.props;
        const { selectedEvent, selectedSlot, calendarDate, openModalEventEditor, width, calendarExpanded } = this.state;
        const { userWorkHours } = user;

        this.renderEventsLoadingOverlay();

        return (
            <StyledComponent
                className='trainer-events-calendar'
                styles={require('./styles')}
            >   
                {events && events.isLoading && Boolean(!events.isLoaded) && (
                    <Spinner />
                )}
                <BigCalendar
                    ref={this.calendarRef}
                    defaultView='week'
                    calendarProps={{
                        min:moment().set('hours', calendarExpanded ? 0 : 6).set('minutes', 0).toDate(),
                        max:moment().set('hours', calendarExpanded ? 23 : 23).set('minutes', calendarExpanded ? 59 : 0).toDate(),
                        selectable: true,
                        date: calendarDate,   
                        onView: view => {
                            this.setState({ calendarView: view }, () => {
                                this.updateTimeHeaderPosition();
                                setTimeout(() => {
                                    this.renderWeekViewTimeHeader(); 
                                    this.setState({
                                        calendarMinifiedViewInitialized: false,
                                    });
                                }, 0);
                            });  
                        },
                        onNavigate: date => {
                            this.setState({ 
                                calendarDate: date,
                                calendarMinifiedViewInitialized: false,
                            });
                            this.renderWeekViewTimeHeader(false);
                        },
                        onSelectSlot: slot => this.setState({ selectedSlot: slot }),
                        onSelectEvent: event => this.setState({ selectedEvent: event }),
                        events: events.elements
                            .filter(event => event)
                            .map(event => ({
                                title: event.category === EVENT_CATEGORY_PRIVATE
                                    ? event.name ? event.name : getCategoryLabel(event).label
                                    : `${event.user && getFullName(event.user, 'nameSurname').label}` + (event.order ? `(Trening: ${event.order}/${event.totalCount})` : '') || 'Brak adepta',
                                start: moment(event.startAt).toDate(),
                                end: moment(event.endAt).toDate(),
                                resource: event,
                                stateColor: this.getStateColor(event),
                            })),
                        components: {
                            event: CustomEvent,
                            timeSlotWrapper: (props) => (<TimeSlotWrapper {...props} openTime={userWorkHours} />),
                            timeGutterHeader: (props) => (
                                <TimeGutterHeader {...props} 
                                    expanded={calendarExpanded} 
                                    onToggleExpand={() => this.setState(prevState => ({
                                        calendarExpanded: !prevState.calendarExpanded
                                    }))}
                                />
                            ),
                        },
                    }}
                />
                <div className="calendar-event-tooltips">
                    <div className="tooltips-legend">
                        Legenda
                    </div>
                    <div className="tooltips-wrapper">
                        <div className="tooltip-block" title="Wykonany">
                            <div className="color-block done" />
                            <div className="label">
                                Wykonany
                            </div>
                        </div>
                        <div className="tooltip-block" title="Zaplanowany">
                            <div className="color-block planned" />
                            <div className="label">
                                Zaplanowany
                            </div>
                        </div>
                        <div className="tooltip-block" title="Niewykonany">
                            <div className="color-block notDone" />
                            <div className="label">
                                Niewykonany
                            </div>
                        </div>
                        <div className="tooltip-block" title="Prywatny">
                            <div className="color-block private" />
                            <div className="label">
                                Prywatny
                            </div>
                        </div>
                        <div className="tooltip-block" title="Odwołany">
                            <div className="color-block canceled" />
                            <div className="label">
                                Odwołany
                            </div>
                        </div>
                    </div>
                </div>
                {width < 1024 && (
                    <FloatButton
                        style='gradient'
                        shape='circle'
                        icon={{
                            type: 'fa',
                            source: 'fas fa-plus',
                        }}
                        onClick={() => this.setState({ openModalEventEditor: true })}
                    />
                )}
                <ModalEventEditor
                    location={location}
                    history={history}
                    isOpen={openModalEventEditor || selectedSlot || selectedEvent || false}
                    onClose={() => this.setState({ selectedEvent: null, selectedSlot: null, openModalEventEditor: false })}
                    data={selectedEvent && selectedEvent.resource || 
                        selectedSlot && { 
                            ...selectedSlot, 
                            startAt: selectedSlot.start, 
                            endAt: selectedSlot.end,
                            user: predefinedQuery && predefinedQuery.user && { ...predefinedQuery.user } || undefined,
                            category: predefinedQuery && predefinedQuery.user ? EVENT_CATEGORY_DEFAULT : undefined,
                        } 
                    || null}
                    forcePlanner={Boolean(selectedSlot)}
                />
            </StyledComponent>
        );
    }
}
