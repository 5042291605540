import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import classnames from 'classnames';

import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';

export default class TrainerMeasurementsChart extends PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            chartData: PropTypes.func.isRequired,
            getMeasurementsConfig: PropTypes.func.isRequired,
        }).isRequired,
        chartData: PropTypes.object,
        predefinedState: PropTypes.object.isRequired,
        measurementsConfig: PropTypes.object,
    };
    static defaultProps = {
        measurementsConfig: {},
    };

    state = {
        chartWidth: 300,
        chartHeight: 300,
        selectedConfig: null,
    }

    measurementsConfigNode = document.body;

    componentDidMount = () => {
        const { actions, predefinedState } = this.props;

        Promise.all([
            actions.chartData({ userId: predefinedState.userId }),
            actions.getMeasurementsConfig({ id: predefinedState.userId }),
        ]).then(() => {
            setTimeout(() => {
                this.setChartDimensions();
            }, 300);
        });

        this.setChartDimensions();
        window.addEventListener('resize', this.setChartDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setChartDimensions);
    }

    componentDidUpdate = () => {
        const { selectedConfig } = this.state;

        if(!selectedConfig) {
            this.setState({ selectedConfig: this.getDefaultConfig() });
        }
    }

    getDefaultConfig = () => {
        const { measurementsConfig, chartData } = this.props;

        const measurementsConfigFields = measurementsConfig?.data?.fieldsConfig || {};
        const configFieldKeys = Object.keys(measurementsConfigFields)
            .filter(measurementsConfigFieldKey => Boolean(measurementsConfigFields[measurementsConfigFieldKey]?.enabled))
            .filter(measurementsConfigFields => chartData?.elements[measurementsConfigFields] && chartData.elements[measurementsConfigFields].length > 1);

        if(configFieldKeys.length > 0) {
            return {
                key: configFieldKeys[0],
                ...measurementsConfigFields[configFieldKeys[0]],
            };
        }

        return null;
    }

    setChartDimensions = () => {
        const componentNode = document.querySelector('.trainer-measurements-chart');
        const controlBoardNode = document.querySelector('.trainer-measurements-chart > .control-board');

        if(!componentNode || !controlBoardNode) {
            return;
        }

        this.setState({
            chartWidth: window.innerWidth > 960
                ? componentNode.offsetWidth * 0.75
                : componentNode.offsetWidth,
            chartheight: controlBoardNode.offsetHeight > 300
                ? controlBoardNode?.offsetHeight
                : 300,
        });
    }

    getEnabledConfigFields = () => {
        const { measurementsConfig } = this.props;
        const fieldsConfig = measurementsConfig?.data?.fieldsConfig || {};

        return Object.keys(fieldsConfig)
            .map(fieldConfigKey => ({
                key: fieldConfigKey,
                ...fieldsConfig[fieldConfigKey],
            }))
            .filter(fieldConfig => Boolean(fieldConfig?.enabled));
    }

    getConfigByKey = (key) => {
        const { measurementsConfig } = this.props;
        const fieldsConfig = measurementsConfig?.data?.fieldsConfig || {};
        return fieldsConfig[key] || null;
    }

    render() {
        const { selectedConfig, chartWidth, chartHeight } = this.state;
        const { measurementsConfig, chartData } = this.props; 

        const isDataLoaded = Boolean(chartData?.isLoaded && measurementsConfig?.isLoaded);
        let configFields = this.getEnabledConfigFields();
        const data = chartData?.elements[selectedConfig?.key] || [];

        return (
            <StyledComponent
                className="trainer-measurements-chart"
                styles={require('./styles')}
            >
                {(!selectedConfig || !isDataLoaded) && (<Spinner />)}
                {(selectedConfig && isDataLoaded) && (
                    <>
                        <LineChart
                            width={chartWidth}
                            height={chartHeight}
                            data={(data || [])
                                .map(elem => ({
                                    ...elem,
                                    date: getFormattedDate(elem.date, 'date'),
                                }))
                            }
                            margin={{ top: 25, left: 25, right: 25, bottom: 25 }}
                        >
                            <CartesianGrid strokeDasharray="10 10" stroke="#333"/>
                            <XAxis dataKey="date" reversed={true}/>
                            <YAxis  domain={['dataMin - 10', 'dataMax + 10']} />
                            <Tooltip />
                            <Line 
                                key={selectedConfig.key}
                                type="linear"
                                stroke="#ea3f48"
                                strokeWidth={3}
                                dataKey="value"
                                name={selectedConfig.name || selectedConfig.key}
                                unit={` ${selectedConfig.unit}`}
                            />
                        </LineChart>

                        {configFields.length > 0 && (
                            <div className="control-board">
                                <div className="board-body">
                                    <div className="body-title">
                                        Wyświetl dostępny pomiar
                                    </div>
                                    <div className="body-controls">
                                        {configFields.filter(element => chartData?.elements[element.key] && chartData.elements[element.key].length > 1).map(configField => (
                                            <a
                                                key={configField.key}
                                                className={classnames(
                                                    'body-control',
                                                    { active: configField.key === selectedConfig.key }
                                                )}
                                                onClick={() => this.setState({ selectedConfig: configField })}
                                            >
                                                <span className="control-icon" />
                                                <span className="control-label">
                                                    {configField.name || 'Nieznany'}
                                                </span>
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </StyledComponent>
        );
    }
}
