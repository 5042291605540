import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/trainer/events/actions';

export default connect(
    state => ({
        events: state.trainerEvents.list,
        user: state.user.profile,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    })
)(Component);
