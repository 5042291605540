import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    color: ${variables.dpc_fontDark};
    width: 40vw;

    .admin-element-editor {
        .editor-wrapper {
            .forms-wrapper {
                width: 100% !important;

                .form-elements {
                    width: 40vw;

                    .form-element {
                        .input-external-wrapper {
                            .input-label {
                                color: white;
                            }
                        }
                    }
                }

                .form-wrapper {
                    border: 0;
                    padding-top: 2em;
                    margin-top: 0;
                }
            }
        }
    }
`;
