import { css } from '@emotion/core';

import variables from 'Theme/styles/vars';

export default () => css`
    display: flex;
    min-height: 80vh;
    border: 1px solid ${variables.dpc_borderDark};
    
    .steps {
        display: flex;
        flex-direction: column;
        background-color: ${variables.dpc_backgroundWhite};
        width: 30%;

        .step {
            border-bottom: .1em solid #666666;
            margin-bottom: 0em;
            position: relative;

            .step-title {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                padding: 2em 1.5em;
                cursor: pointer;

                .step-title-headline {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .step-title-headline-counter {
                        color: #DF3846;
                        font-size: 1.3em;
                        text-align: left;
                    }
                    .step-title-headline-content {
                        color: ${variables.dpc_fontDark};
                        font-size: 1.3em;
                        text-align: left;
                        margin-left: 1em;
                        display: flex;
                        align-items: center;

                        .control {
                            font-size: .5em;
                            padding-left: 1em;
                        }
                    }
                }
                .step-title-value {
                    color: ${variables.dpc_fontDark};
                    font-size: 1em;
                    font-weight: 300;
                    margin-top: .5em;
                    text-align: left;
                }
            }
            .step-body {
                padding: 0 3em 2em 3em;
                
            }

            &:not(.disabled):hover {
                background: ${variables.dpc_backgroundLightRed};
                cursor: pointer;
            }

            &.active {
                background: ${variables.dpc_backgroundLightRed};
            }

            &.disabled {
                .step-title {
                    cursor: default;
                }
            }
        }
        
        .footer {
            margin-top: auto;

            .component-button {
                width: 100%;

                button {
                    border-left: none;
                    border-right: none;
                    border-bottom: none;
                }
            }
        }
    }

    .step-body-container {
        background-color: ${variables.dpc_backgroundWhite};
        width: 70%;
        padding: 2em;
        border-right: 1px solid ${variables.dpc_borderDark};


        .step {
            .step-body {
                .list-headline {
                    font-size: 1.75em;
                    font-weight: 300;
                    color: ${variables.dpc_fontDark};
                    margin-bottom: 1em;    
                }
           
                .adept-events-planner-step-user-offer {
                    .adept-user-offers-list {
                        .admin-paginated-list  {
                            .list-body {
                                .admin-paginated-list-element-v2 {
                                    height: 12em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
