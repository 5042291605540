import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject, resetQueryString } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class TrainerLocationsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        locations: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapClassName: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapImage: PropTypes.func,
        onMapType: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
        resetQuery: PropTypes.bool,
    };
    static defaultProps = {
        onMapClassName:  (elem, value) => value,
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapImage: (elem, value) => value,
        onMapType: (elem, value) => value,
        onMapTitle: (elem, value) => value,
        onMapSubTitle: (elem, value) => value,
        resetQuery: false,
    };

    defaultQuery = {
        page: 1,
        perPage: 8,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { location, history, resetQuery } = this.props; 
        
        if (resetQuery) {
            resetQueryString(location, history);
        }

        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { 
            locations, 
            location, 
            history,
            onMapClassName,
            onMapControls,
            onMapAdditionals,
            onMapFilters,
            onMapLabels,
            onMapType,
            onMapImage,
            onMapTitle,
            onMapSubTitle,
        } = this.props;

        return (
            <StyledComponent
                className="adept-locations-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={locations}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            className={onMapClassName(element, '')}
                            type={onMapType(element, undefined)}
                            image={onMapImage(element, undefined)}
                            title={onMapTitle(element, element.name)}
                            subtitle={onMapSubTitle(element, element.address)}
                            additionals={onMapAdditionals(element, [])}
                            labels={onMapLabels(element, [])}
                            controls={onMapControls(element, [])}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                        styleProps: {
                            styleVersion: 2,
                            border: 'bottom-light',
                        },
                    }])}
                />
            </StyledComponent>
        );
    }
}
