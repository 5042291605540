import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { resetQueryString } from 'Utils/querystring';
import { fromSelectObject } from 'Utils/object'; 

import { EVENT_CATEGORIES } from 'Consts/events';

import StyledComponent from 'Components/core/StyledComponent';
import Select from 'Components/forms/MultiSelect';

export default class TrainerEventsPlannerStepEventCategory extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        formState: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
    };
    static defaultProps = {};

    componentDidMount = () => {
        const { location, history } = this.props;

        resetQueryString(location, history);
    }

    render() {
        const { onChangeFormState } = this.props;
        
        return (
            <StyledComponent
                className="trainer-events-planner-step-event-category"
                styles={require('./styles')}
            >
                <h3 className="list-headline">
                    Wybierz kategorię
                </h3>
                <Select
                    name="category"
                    placeholder="Kategoria" 
                    options={EVENT_CATEGORIES.map(category => ({
                        value: category.key,
                        label: category.label,
                    }))}
                    style="transparentV2"
                    onChange={(category) => {
                        onChangeFormState({ category: fromSelectObject(category.value) });
                    }}
                    reactSelectProps={{
                        isSearchable: false,
                    }}
                />
            </StyledComponent>
        );
    }
}