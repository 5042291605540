import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default () => css`
    .steps {
        background-color: ${variables.dpc_backgroundWhite};

        .step {
            border-radius: .5em;
            border-bottom: .1em solid #666666;
            margin-bottom: 1em;

            .step-control {
                display: flex;
                justify-content: flex-end;
                padding: 1em 1em 0 0;
            }

            .step-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: .5em 3em;
                cursor: pointer;

                .step-title-headline {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .step-title-headline-counter {
                        color: #DF3846;
                        font-size: 1.6em;
                        text-align: left;
                    }
                    .step-title-headline-content {
                        color: ${variables.dpc_fontDark};
                        font-size: 1.6em;
                        text-align: left;
                        margin-left: 1em;
                        display: flex;
                        align-items: center;

                        .control {
                            font-size: .5em;
                            padding-left: 1em;
                        }
                    }
                }
                .step-title-value {
                    color: ${variables.dpc_fontDark};
                    font-size: 1.2em;
                    font-weight: 600;
                    margin-top: .5em;
                    text-align: right;
                }
            }
            .step-body {
                padding: 0 3em 2em 3em;

                .list-headline {
                    color: ${variables.dpc_fontDark};
                    margin-bottom: .2em;
                }
            }

            &:not(.disabled):hover {
            }

            &.active {
                border-color: #DF3846;
            }

            &.disabled {
                .step-title {
                    cursor: default;
                }
            } 
        }
    }

    @media (max-width: ${variables.tabletL}) {
        .steps {
            .step {
                .step-title {
                    flex-wrap: wrap;

                    .step-title-headline {
                        width: 100%;
                    }
                    .step-title-value {
                        text-align: left;
                    }
                }
            }
        }
    }
`;
