import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .list-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .product-link {
            margin-right: 1em;
            margin-bottom: 1em;
            cursor: pointer;

            .admin-panel-text-box {
                
            }

            &:hover {
                .admin-panel-text-box {
                    background-color: ${variables.dpc_backgroundLightRed};
                }
            }
            &.active {
                .admin-panel-text-box {
                    background-color: ${variables.dpc_backgroundLightRed};
                }
            }
        }   
    }
`;
