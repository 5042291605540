import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        
        .admin-panel-text-box {
            margin-right: 1em;
            margin-bottom: 1em;
            width: 48% !important;

            .admin-panel-box {
                padding: 3em;
            }
        }

        .not-visible {
            min-height: 9em;
            position: relative;
            width: 48%;
        }

        @media (max-width: ${variables.desktopM}) {
            .admin-panel-text-box {
                width: 31%;
            }
        }
        
        @media (max-width: ${variables.tabletL}) {
            .admin-panel-text-box {
                width: 48%;
            }
        }
        
        @media (max-width: ${variables.tabletS}) {
            .admin-panel-text-box {
                width: 100%;
            }
        }
    `;
