import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_USERS_NOTES, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const presignFile = dispatch => (params = {}) => {
    return dispatch(request({
        method: 'POST',
        reduxType: types.FILE_PRESIGN,
        path: params.presignPath,
        timeout: 360000,
        requestParams: {
            file: {
                type: params.file?.type,
                name: params.file?.name,
                size: params.file?.size,
            },
        },
    }));
};