import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    cancel,
    stats,
    done,
    reset,
    locationOccupancy,
} from 'Redux/modules/trainer/events/actions';

import { list as listProducts } from 'Redux/modules/trainer/products/actions';
import { list as listUsers } from 'Redux/modules/trainer/users/actions';
import { list as listUserOffers } from 'Redux/modules/trainer/userOffers/actions';
import { list as listLocations } from 'Redux/modules/trainer/locations/actions';

export default connect(
    state => ({
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            cancel: dispatch(cancel),
            listProducts: dispatch(listProducts),
            listUsers: dispatch(listUsers),
            listUserOffers: dispatch(listUserOffers),
            listLocations: dispatch(listLocations),
            stats: dispatch(stats),
            done: dispatch(done),
            reset: dispatch(reset),
            locationOccupancy: dispatch(locationOccupancy),
        },
    }),
)(Component);