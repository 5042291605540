import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        justify-content: flex-start;
        margin: 0;
        margin-bottom: 1em;
        flex-wrap: wrap;

        .component-button {
            width: 19%;
            margin-right: 1%;
            margin-bottom: .5em;
            
            button {
                width: 100%;
                background: #444 !important;
                border: none !important;
                box-shadow: none !important;
            }
        }

        @media (max-width: ${variables.tabletL}) {
            .component-button {
                width: 19%;
                margin-right: 1%;
                margin-bottom: 1%;
            }
        }
        
        @media (max-width: ${variables.tabletS}) {
            .component-button {
                width: 24%;
                margin-right: 1%;
                margin-bottom: 1%;
            }
        }
        
        @media (max-width: ${variables.mobileL}) {
            justify-content: space-between;

            .component-button {
                width: 48%;
                margin-bottom: 2.5%;
            }
        }     
    `;
