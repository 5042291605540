import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import LocationsList from 'Components/trainer/locations/List';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class TrainerEventsPlannerStepLocation extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        formState: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
    };
    static defaultProps = {}; 

    state = {}

    render() {
        const { location, history, formState, onChangeFormState } = this.props;
        
        return (
            <StyledComponent
                className="trainer-events-planner-step-product"
                styles={require('./styles')}
            >
                {formState.location && (
                    <div className="selected-container">
                        <h2 className="list-headline">
                            Wybrana lokalizacja
                        </h2>
                        <PaginatedListElement
                            title={formState.location.name}
                            subTitle={formState.location.address}
                            controls={[{
                                type: 'button',
                                label:  'Zmień',
                                visible: true,
                                onClick: () => onChangeFormState({ location: null }),
                                style: 'hollow',
                            }]}
                        />
                    </div>
                )}
                {(!formState.location) && (
                    <>
                        <h3 className="list-headline">
                            Wybierz lokalizację
                        </h3>
                        <LocationsList
                            location={location}
                            history={history}
                            predefinedQuery={{
                                types: [
                                    formState?.product?.locationType,
                                    formState?.product?.secondLocationType,
                                    formState?.product?.thirdLocationType,
                                ],
                            }}
                            onMapControls={element => [{
                                type: 'button',
                                label: 'Wybierz',
                                visible: true,
                                onClick: () => onChangeFormState({ location: element }),
                                style: 'hollow',
                            }]}
                            resetQuery={true}
                        />
                    </>
                )}
            </StyledComponent>
        );
    }
}