import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
    .admin-element-editor {
        .editor-wrapper {
            .forms-wrapper {
                width: 800vw;
            }
        }
    }
    `;
