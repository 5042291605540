import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        padding: 0;
        
        .big-calendar-wrapper {
            .rbc-calendar {
                .rbc-time-header {
                    background: ${variables.dpc_backgroundWhite};
                    z-index: 1;
                    
                    .rbc-time-header-gutter {
                        background: ${variables.dpc_backgroundWhite};
                        font-size: .75em;
                        font-weight: 600;
                        text-align: center;
                        cursor: pointer;
                        padding-top: 1.2em;
                    }
                }

                .rbc-time-header-cell {
                    text-transform: uppercase;
                }

                .rbc-toolbar {
                    position: sticky;
                    top: 0;
                    z-index: 5;
                    background-color: #333333;

                    .rbc-toolbar-label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 2.3em;
                        background-color: #333333;
                    }

                    .toolbar-time-header-container {  
                        position: absolute;
                        background-color: #333333;
                        width: 100%;
                        top: 0em;
                        z-index: -5;
                        opacity: 0;
                        transition: all .5s;
                        border-right: .5px solid #444;

                        .day-names {
                            .rbc-header {
                                border-bottom: none;
                                padding-top: .5em;
                            }

                            .rbc-time-header-gutter {
                                border-bottom: none;
                                background: #292929;
                                font-size: .7em;
                                font-weight: 600;
                                text-align: center;
                                cursor: pointer;
                                padding: 0;
                                margin: 0;
                                padding-top: 1.2em;
                            }
                        }
                        
                        .rbc-header {
                            padding-bottom: .5em;
                        }

                        .rbc-time-header-gutter {
                            border-left: .5px solid #444;
                            border-bottom: .5px solid #444;
                        }

                        &.visible {
                            top: 2.3em;
                            opacity: 1;
                        }

                        &.visible-mobile {
                            top: 6.95em;
                            opacity: 1;
                        }
                    }
                }

                .rbc-time-view {
                    overflow: hidden;

                    .day-names {
                        .rbc-header {
                            border-bottom: none;
                            padding: .25em 0;
                        }
                    }

                    .rbc-time-content {
                        .rbc-day-slot {
                            .rbc-timeslot-group {
                                .closed {
                                    background-color: #EEEEEE;
                                }
                            }
                        }
                    }
                }

                .rbc-btn-group {
                    background-color: #333333;
                    button {
                        background: none !important;
                        padding: .75em 1.5em !important;
                        border-radius: 0 !important;
                        border-color: #D83143 !important; 
                        color: white !important;
                        text-transform: uppercase;
                    }
                        
                    .rbc-active {
                        background: #D83143 !important;
                    }
                }

                .overlay {
                    position: absolute;
                    display: none;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    background: black;
                    opacity: 0.9;

                    &.visible {
                        display: block;
                    }
                }
            }

                .rbc-time-content {
                    overflow: hidden;
                }

                .rbc-event {
                    display: flex !important;
                    flex-direction: row !important;
                    border-radius: 0 !important; 
                    color: white !important;
                    text-transform: capitalize;
                    padding: 0 !important;
                    background: none !important;

                    .rbc-event-label {
                        display: block !important;
                        width: 100% !important;
                        padding: 0 .3em;
                        background: #313131;
                    }

                    .rbc-event-content {
                        width: 100%;
                        height: 100%; 

                        .custom-event {
                            width: 100%;
                            height: 100%;
                            padding: 0 .3em;

                            &.private {
                                background: #787878;
                            }

                            &.done {
                                background: #1e8000
                            }

                            &.notDone {
                                background: #D83143;
                            }

                            &.notDoneExpired {
                                background: #D83143;
                            }

                            &.plannedPaid {
                                background: #5f72ff;
                            }

                            &.plannedNotPaid {
                                background: #5f72ff;
                            }

                            &.canceled {
                                background: #1f1f1d;
                            }

                            &.foreign {
                                background: black;
                                opacity: 0.4;
                            }
                        }
                    }

                }
            }
        }

        .calendar-event-tooltips {
            display: flex;
            flex-direction: column;

            .tooltips-legend {
                color: ${variables.dpc_fontDark};
                font-weight: 600;
                margin: .75em 0;
            }

            .tooltips-wrapper {
                display: flex;
            
                .tooltip-block {
                    width: 25%;
                    height: .75em;
                
                    .color-block {
                        height: 100%;
                        &.done {
                            background: #1E8000;
                        }

                        &.planned {
                            background: #5f72ff;
                        }

                        &.notDone {
                            background: #D83143;
                        }

                        &.private {
                            background: #787878;
                        }

                        &.canceled {
                            background: #1f1f1d;
                        }
                    }

                    .label {
                        color: ${variables.dpc_fontDark};
                        font-weight: 300;
                        margin-top: .5em;
                    }   
                }
            }
        }
        
        .time-gutter-header-custom {
            display: none; 
        }

        .rbc-time-view > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
            display: block;
        }

        @media (max-width: ${variables.desktopS}) {
            .rbc-calendar {
                .rbc-toolbar {
                    display: flex;
                    flex-wrap: wrap;

                    .rbc-toolbar-label {
                        padding-top: 1em;
                        padding-bottom: 1em;
                    }

                    .rbc-btn-group {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        width: 100%;

                        &:nth-of-type(1) {
                            button {
                                width: 33.3%;
                            }
                        }

                        &:nth-of-type(3) {
                            button {
                                width: 25.07%;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletS}) {
            .calendar-event-tooltips {
                .tooltips-legend {
                }

                .tooltips-wrapper {
                    display: flex;
                    flex-wrap: wrap;

                    .tooltip-block {
                        display: flex;
                        flex-direction: column-reverse;
                        width: 100%;
                        height: 2.5em;

                        .label {
                            font-weight: 400;
                            margin-bottom: .25em;
                        }   
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileM}) {
            .rbc-toolbar {
                .rbc-btn-group {
                    button {
                        font-size: .5em;
                        padding: 1.5em .2em !important;
                    }
                    
                    &:nth-of-type(3) {
                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    `;
