import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import MedicalCardsButtons from 'Components/common/medical/MedicalCardsButtons/component';

export default class TrainerMedicalPanel extends Component {
    static propTypes = {
        // Redux props
        actions: PropTypes.shape({
            currentMedicalCard: PropTypes.func.isRequired,
            list: PropTypes.func.isRequired,
            single: PropTypes.func.isRequired,
        }),
        medicalCard: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            isLoading: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }).isRequired,
        medicalCardsList: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            isLoading: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }).isRequired,
        predefinedQuery: PropTypes.object,
    }
    static defaultProps = {};

    state = {
        categories: [],
        questions: [],
    };

    componentDidMount = () => {
        const { actions, predefinedQuery } = this.props;
        actions.currentMedicalCard({ ...predefinedQuery });
        actions.list({ ...predefinedQuery });
    }

    componentDidUpdate = prevProps => {
        const { medicalCard } = this.props;

        if (medicalCard && prevProps.medicalCard && medicalCard.data && JSON.stringify(medicalCard.data) !== JSON.stringify(prevProps.medicalCard.data)) {
            this.parseData(medicalCard.data);
        }
    }

    parseData = data => {
        let categories = [];
        let questions = [];

        data.answers
            .filter(answer => answer && answer.question && answer.question.category)
            .forEach(answer => {
                let categoryIndex = categories.findIndex(category => category.id === answer.question.category.id);
                if (categoryIndex === -1) {
                    categories.push({
                        ...answer.question.category,
                        answers: [],
                    });
                    categoryIndex = categories.length - 1;
                }

                questions.push(answer.question);
                answer.question.subQuestions.map(subQuestion => {
                    questions.push(subQuestion);
                });

                categories[categoryIndex] = {
                    ...categories[categoryIndex],
                    answers: [
                        ...categories[categoryIndex].answers,
                        answer,
                    ],
                };
            });

        this.setState({
            categories,
            questions,
        });
    }

    getQuestionById = id => {
        const { questions } = this.state;

        return questions.find(question => question.id === id);
    }

    renderSubquestion = (id, value) => {
        const question = this.getQuestionById(id);

        if (!question) return null;

        return (
            <span className='answer-value-text'>
                <span>{question.title}</span>
                : 
                <span> {value}</span>
            </span>
        );
    }

    onMedicalCardClick = element => {
        const { actions } = this.props;

        actions.single({ id: element.id });
    }

    render() {
        const { medicalCard, medicalCardsList } = this.props;
        const { categories } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="trainer-medical-panel"
            >
                {!medicalCard || (medicalCard && medicalCard.isLoading) && (
                    <Spinner />
                )}

                {medicalCardsList && medicalCardsList.isLoaded && (
                    <MedicalCardsButtons
                        medicalCards={medicalCardsList}
                        onElementClick={this.onMedicalCardClick}
                    />
                )}
                {medicalCard && !medicalCard.data && !medicalCard.isLoading && (
                    <div className="no-card-container">
                        <h2 className="headline">
                            Adept nie posiada uzupełnionej kartoteki medycznej
                        </h2>
                    </div>
                )}

                {medicalCard && medicalCard.data && (
                    <div className="card-container">
                        <h2 className="card-headline">
                            Wywiad medyczny z dnia {getFormattedDate(medicalCard.data.measureDate, 'date')}
                        </h2>
                        <div className="categories">
                            {categories.map(category => (
                                <div
                                    key={category.id}
                                    className="category"
                                >
                                    <h3 className="category-headline">
                                        {category.name}
                                    </h3>
                                    <div className="category-answers">
                                        {category.answers.map(answer => (
                                            <div
                                                key={answer.id}
                                                className="answer"
                                            >
                                                <h4 className="answer-question">
                                                    {answer.question.title}
                                                </h4>
                                                <div className="answer-value">
                                                    <span className={`answer-value-bool ${Boolean(answer.value)}`}>
                                                        {answer.value
                                                            ? 'Tak'
                                                            : 'Nie'
                                                        }
                                                    </span>
                                                    {answer.additionalData && typeof answer.additionalData == 'string'
                                                        ? Object.keys(JSON.parse(answer.additionalData)).map(key => this.renderSubquestion(key, JSON.parse(answer.additionalData)[key]))
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </StyledComponent>
        );
    }
}