import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';

export default class FloatButtonComponent extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        type: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
        style: PropTypes.oneOf([
            'default',
            'gradient',
        ]),
        size: PropTypes.oneOf([
            'small',
            'medium',
            'large',
        ]),
        shape: PropTypes.oneOf([
            'default',
            'circle',
        ]),
        icon: PropTypes.shape({
            type: PropTypes.oneOf(['fa', 'path']).isRequired,
            source: PropTypes.string.isRequired,
        }),
        disabled: PropTypes.bool,
        confirm: PropTypes.shape({
            enabled: PropTypes.bool,
            message: PropTypes.string,
        }),
        loading: PropTypes.bool,
        form: PropTypes.string,
    };

    static defaultProps = {
        style: 'primary',
        size: 'medium',
        className: '',
        onClick: null,
        type: 'button',
        disabled: false,
        confirm: null,
        loading: false,
        form: null,
        shape: 'default',
    };

    defaultConfirmProps = {
        message: 'Potwierdź swój wybór',
    };

    getIcon = icon => {
        switch (icon.type) {
            case 'fa':
                return (<i className={icon.source} />);
            case 'path':
                return (<img src={icon.source} />);
        }
    }

    onClick = () => {
        const { onClick, confirm, disabled, loading } = this.props;

        if(disabled || loading) {
            return null;
        }

        if (confirm && confirm.enabled !== false) {
            const confirmConfig = { ...this.defaultConfirmProps, ...confirm };

            if (window.confirm(confirmConfig.message)) {
                return onClick && onClick();
            } else {
                return null;
            }
        }

        return onClick && onClick();
    }

    render() {
        const { children, type, className, icon, disabled, size, style, loading, form, shape } = this.props;

        return (
            <StyledComponent styles={require('./styles')} className="float-button-component">
                <button
                    type={type}
                    onClick={disabled ? null : this.onClick}
                    disabled={disabled}
                    form={form || undefined}
                    className={classnames(
                        'button',
                        className,
                        `size-${size}`,
                        `style-${style}`,
                        `shape-${shape}`,
                        {
                            disabled,
                        }
                    )}
                >
                    <span className="button-content">{loading ? <Spinner /> : children}</span>
                    {icon && <div className="button-icon">{this.getIcon(icon)}</div>}
                </button>
            </StyledComponent>
        );
    }
}
