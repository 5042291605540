import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .trainer-adept-tests-editor-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .trainer-adept-tests-editor-header-col  {
                display: flex;
                align-items: center;

                &.col-left {
                    justify-content: flex-start;

                    .trainer-adept-tests-editor-header-headline {
                        font-size: 1.5em;
                        color: white;
                        font-weight: 400;
                        margin-right: 2em;
                    }
                }
                
                &.col-right {
                    justify-content: flex-end;
                }


                .trainer-adept-tests-editor-header-datepicker {
                    margin-left: 0em;
                }
            }
        }

        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    width: 100% !important;
                    padding-right: 0 !important;

                }
                .data-wrapper {
                    display: none;
                }
            }
        }

        @media (min-width: ${variables.tabletL}) {
            .admin-element-editor {
                .editor-wrapper {
                    .forms-wrapper {
                        .box:not(.size-fullWidth) {
                            width: 32%;
                        }
                    }
                }
            }
        }
    `;
