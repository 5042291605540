import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_TRAINING_TARGETS_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class AdminTrainingTargetsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        trainingTargets: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { trainingTargets, location, history } = this.props;

        return (
            <StyledComponent
                className="trainer-training-targets-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={trainingTargets}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.name || 'Brak nazwy'}
                            additionals={[{
                                name: 'Cel główny?',
                                value: element.primary ? 'Tak' : 'Nie',
                            }, {
                                name: 'Data rozpoczęcia',
                                value: element.startAt && getFormattedDate(element.startAt, 'date') || 'Brak',
                            }, {
                                name: 'Przewidywana data zakończenia',
                                value: element.estimatedTo && getFormattedDate(element.estimatedTo, 'date') || 'Brak',
                            }, {
                                name: 'Data zakończenia',
                                value: element.finishAt && getFormattedDate(element.finishAt, 'date') || 'Brak',
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(TRAINER_TRAINING_TARGETS_MANAGE.path, { id: element.id }),
                                style: 'gradient',
                            }]}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}
