import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        min-height: 100vh;

        .expand-details {
            text-align: center;

            .expand-details-headline {
                font-size: 2em;
                color: ${variables.dpc_fontDark};
                text-align: left;
                border-bottom: 1px solid black;
            }

            .expand-details-link {
                color: ${variables.dpc_fontDark};
                text-decoration: underline;
                font-size: 1.1em;
                cursor: pointer;
            }
        }

        .comments {
            padding-top: 2em;
        }

        .notes-editor-header-col {
            .notes-editor-header-headline {
                color: white;
            }

            &.col-right {
                margin-top: 2em;
            }
        }

        .admin-element-editor {

            .editor-wrapper {
                .forms-wrapper {
                    width: 100% !important;
                    padding-right: 0 !important;

                    .form-wrapper {
                        padding-top: 0;
                        border: none;

                        .form-elements {
                            .panel-measurements-box {
                                width: 96%;

                                .admin-panel-box {
                                    .measurements-box-content {
                                        .measurements-box-body {
                                            .measurements-box-children {
                                                .box-body{
                                                    .box-input{
                                                        .form-element{
                                                            .input-external-wrapper {
                                                                .input-label{
                                                                    color: ${variables.dpc_fontDark};
                                                                    font-size: .9em;
                                                                    
                                                                    span {
                                                                        font-weight: 300;
                                                                    }
                                                                }

                                                                .upload-queue {
                                                                    .upload-queue-elem {
                                                                        .upload-queue-elem-details {
                                                                            .upload-queue-elem-image-container {
                                                                                filter: brightness(1.2);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            .dropzone-container {
                                                                .dropzone-internal {
                                                                    .info {
                                                                        .dropzone-label {
                                                                            color: black;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .noteType {
                        width: 100%;
                    }
                }

                .data-wrapper {
                    display: none;
                }
            }
        }
    `;
