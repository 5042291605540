import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Spinner from 'Components/layout/Spinner/component';
import Button from 'Components/layout/Button/component';
import StyledComponent from 'Components/core/StyledComponent/component';

export default class MedicalCardsButtons extends React.Component {
    static propTypes = {
        medicalCards: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            isLoading: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }),
        onElementClick: PropTypes.func.isRequired,
    };

    render() {
        const { medicalCards, onElementClick } = this.props;

        if (medicalCards.isLoading) {
            return (
                <Spinner />
            );
        }

        return (
            <StyledComponent
                styles={require('./styles')}
                className='medical-cards-buttons'
            >
                {medicalCards.elements.map(element => (
                    <Button
                        key={element.id}
                        style="faPrimary"
                        size="large"
                        onClick={() => onElementClick(element)}
                    >
                        {element.measureDate
                            ? moment(element.measureDate).format('YYYY-MM-DD')
                            : moment(element.createdAt).format('YYYY-MM-DD')
                        }
                    </Button>
                ))}
            </StyledComponent>
        );
    }
}