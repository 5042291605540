import { connect } from 'react-redux';
import Component from './component';

import { progress } from 'Redux/modules/trainer/measurements/actions';
import { single as config } from 'Redux/modules/trainer/measurementsConfig/actions';

export default connect(
    state => ({
        configMap: state.trainerMeasurementsConfig.mapById,
        progress: state.trainerMeasurements.progress,
    }),
    dispatch => ({
        actions: {
            config: dispatch(config),
            progress: dispatch(progress),
        },
    }),
    (stateProps, dispatchProps, ownProps) => {
        const { predefinedState } = ownProps;

        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
            config: stateProps.configMap[predefinedState.userId],
        };
    }
)(Component);
