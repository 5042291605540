import { connect } from 'react-redux';
import Component from './component';

import { 
    current as currentMedicalCard,
    list,
    single, 
} from 'Redux/modules/trainer/medicalCards/actions';

export default connect(
    state => ({
        medicalCard: state.trainerMedicalCards.current,
        medicalCardsList: state.trainerMedicalCards.list,
    }),
    dispatch => ({
        actions: {
            currentMedicalCard: dispatch(currentMedicalCard),
            list: dispatch(list),
            single: dispatch(single),
        },
    })
)(Component);