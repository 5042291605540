import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class PanelUserCommentSingleEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
        },
    };

    onSubmit = () => {
        const { formState } = this.state;
        const { data, actions, onSuccess } = this.props;

        return actions.update({
            id: data.id,
            content: formState.content,
        })
            .then(() => {
                onSuccess();
            });
    }

    render() {
        const { location, history } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="panel-user-comment-single-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            isVisible: true,
                            type: 'textarea',
                            name: 'content',
                            label: 'Treść',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}